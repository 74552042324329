export default {
  meau: {
    nav: '首页',
    // nav2: '社区',
    // nav3: '下载客户端',
    // nav4: '节点奖励',
    // nav5: 'NFT',
    // nav6: '云网吧',
    // nav7: 'GameFi',

    nav1: '购买挖矿节点',
    nav2: '猎户座GPU竞赛',
    nav3: 'DeepLink软件',
    nav3_1: '软件下载',
    nav3_2: '软件许可证',
    nav4: '带宽挖矿',
    nav5: '云网吧',
    nav6: '博客',
    nav7: '文档',
  },
  cont1: {
    text1: '专注于游戏的远程控制软件',
    text2: '云游戏平台',
    btn: '立即免费下载',
    btn1: 'PC 下载',
    btn2: 'WEB 远程',
    btn3: 'Android 下载',
    btn4: 'Google Play',
    version: '版本 {ver}',
    text3_1: '加入',
    text3_2: '社区',
    text3_3: '获得DeepLink Coin奖励'
  },
  cont2: {
    text1: 'DeepLink',
    text2: '依托电竞级的低延迟技术能力，畅玩游戏',
    text3: '核心能力与技术优势',
    text4: '基于云游戏核心能力，体验远超传统远控！',
    leaf1: {
      text1: '高流畅、高性能',
      text2: '低成本',
      text3: '业界一流的编解码方案，同码率性能消耗与资源占用最小。',
      text4: 'peer-to-peer:  支持ipv6，高达 95% NAT打通率，零带宽占用。'
    },
    leaf2: {
      text1: '高帧率、低延迟',
      text2: '',
      text3: '局域网延迟＜15ms，并具备4K 240Hz的能力，广域网评测也处于全球第一阵营。'
    },
    leaf3: {
      text1: '高清晰',
      text2: '',
      text3: '智能算法提升同等码率下的可视效果，还原最逼真的色彩表现。'
    },
    leaf4: {
      text1: '高稳定',
      text2: '',
      text3: '大量数据支撑下的抗丢包、抗拥塞策略，可快速地对数据包丢失和拥塞进行调整。'
    },
    leaf5: {
      text1: '高安全',
      text2: '',
      text3: '所有数据通过AES256的DTLS1.2加密 。'
    }
  },
  cont3: {
    text1: '丝滑流畅，就像操控本地设备',
    text2: '应用三大领域，数十项的优化技术，DeepLink可实现在发生5%丢包率、100ms网络延迟*的情况下，依旧保持高帧率流畅运行。满足各类网络环境的使用要求。',
    li1: {
      text1: '弱网对抗技术',
      text2: 'FEC抗丢包策略',
      text3: '带宽预测',
      text4: 'SVC编码策略'
    },
    li2: {
      text1: '传输优化技术',
      text2: '端口指纹预测',
      text3: 'IPV6支持',
      text4: 'UPNP',
      text5: '智能选路'
    },
    li3: {
      text1: '显示处理技术',
      text2: '帧率自适应策略',
      text3: '动态画面预测',
      text4: 'Jitter处理策略',
      text5: 'AI低码高清渲染',
      text6: '动态虚拟现实器'
    },
    desc3: '局域网 < 5 ms  ·  城际网络 < 10 ms  ·  跨省网络 < 30 ms'
  },
  cont4: {
    text1: '超清晰的图形画质',
    text2: '极清分辨率',
    text3: '10.7亿色彩支持',
    text4: '4:4:4 无损画质',
    text5: 'DeepLink始终采用更苛刻的 画质指标，以实现远超其他传统远程技术的 图像体验，远程图像失真度低于人眼可识别极限值，全力支撑创意行业对画面显示效果的精准要求。',
    text6: '*PSNR指峰值信噪比，低于20dB时画面失真度度高，20—30dB时画面失真度一般，30—40dB时画面失真度低，大于40dB时画面失真度极低且肉眼不可察觉。'
  },
  cont5: {
    text1: '丰富的外设支持',
    text2: 'DeepLink为创意行业提供了 数位板、手柄 等专业外设的远程支持，并针对PS、Zbrush、Unity等创意软件的进行使用优化，确保了远程状态与本地状态下的创意生产工具 体验一致性。'
  },
  cont6: {
    text1: '多重保障 · 极简操作',
    block1: {
      title1: '账号安全',
      desc1: '多层账号保护机制，放心用',
      title2: '连接安全',
      desc2: 'DTLS1.2协议保护，有授权才能访问',
      title3: '数据安全',
      desc3: '256位AES加密 传输全过程保密',
      title4: '设备安全',
      desc4: '按您的设置，只让对应的人看到对应的设备'
    },
    block2: {
      title1: '多重安全保障',
      desc1: '为每次连接保驾护航',
      title2: '操作简单',
      desc2: '轻松启动',
    },
    block3: {
      title1: '下载轻量',
      desc1: 'DeepLink客户端仅约10M',
      title2: '上手简单',
      desc2: '根据指引即可完成配置流程',
      title3: '使用轻松',
      desc3: '通过鼠标就可远控权限内的任意设备'
    }
  },
  cont7: {
    text1: '致力于提供远控解决方案',
    block1: {
      word1: '教育行业',
      word2: '游戏行业',
      word3: '创意设计行业',
      word4: '信息技术行业',
      word5: '出差的业务人员',
      word6: '分散在各地的协作者',
      word7: 'IT运维远程维护 ',
      word8: '个人用户和游戏玩家'
    },
    block2: {
      text1: '支持各行业的远控场景',
      text2: '基于各行业的企业实际需求，助力企业节能高效地实现业务目标。',
      text3: '提供个性化定制和加密策略、灵活安全',
      text4: '公司员工能够随时随地根据实际权限访问公司资源。'
    },
    block3: {
      text1: '覆盖多种用户需求',
      text2: '不管您是选择给企业内部员工使用，',
      text3: '还是外部客户、合作者、甚至个人使用。',
      text4: '我们的远控能力能在主流平台、设备上为您服务，',
      text5: '提供另一种简单、聪明的问题解决途径。'
    }
  },
  cont9: {
    block1: {
      text1: '多重安全保障',
      text2: '为每次连接保驾护航',
    },
    block2: { 
      title1: '网吧共享',
      text1_1: '256位AES加密',
      text1_2: '传输全过程保密',
      title2: '连接安全',
      text2_1: 'DTLS1.2协议保护',
      text2_2: '有授权才能访问',
      title3: '设备安全',
      text3_1: '按您的设置，只让对应的人看到对应的设备',
      title4: '账号安全',
      text4_1: '基于区块链技术的多层账号保护机制，设备信息，不经过中心化服务器，点对点加密传输',
    },
    block3: {
      text1: '网吧共享',
      text2: '网吧主可以通过DeepLink出租网吧机器，赚取收益，用户可以通过DeepLink在家畅玩周围50公里网吧游戏',
    },
  },
  cont10: {
    title: 'DeepLink 提供云网吧解决方案',
    desc: '通过降低成本和无限扩展性，为网吧最大限度的提高利润率',
    block1: {
      title1: '现有网吧的四大问题',
      text1: '电费又要上涨了吗？',
      text1_1: '电费负担',
      text1_2: '根据政府政策提高电费。',
      text2: 'RTX-40XX网吧能引入多少？',
      text2_1: 'GPU/CPU升级负担',
      text2_2: '每当新GPU发布，都会带来昂贵的GPU升级购买负担。',
      text3: '能否改变消费者的付费认知？',
      text3_1: '付费认知',
      text3_2: '为了改变限制在一美元内的付费固定意识，需要一个理由改变现状。',
      text4: '如何面对外界环境因素的影响？',
      text4_1: '网吧PC利用率低',
      text4_2: '由于大流行病，天气等原因，去网吧的用户减少，存在处置闲置的电脑的问题。',
    },
    block2: {
      title1: '云网吧连锁店',
      desc1: '云网吧价值：降低创业成本和维护成本，无需额外升级成本，为网吧店主提供最优经济效益。',
      text1_1: '费用降低61%',
      text1_2: '减轻、降低创业成本和PC采购费用',
      text2_1: '超低延迟',
      text2_2: '提供高性能算力，提升游戏响应速度',
      text3_1: '“零”维修费用',
      text3_2: '不存在台式机管理成本',
      text4_1: '营业额增加',
      text4_2: '按照GPU型号，分别设置付费标准，所有席位均可设定为”高级区域“',
      text5_1: '节省电费',
      text5_2: '节能减排，减少碳排放',
      text4: '如何面对外界环境因素的影响？',
      text6_1: '“零”升级费用',
      text6_2: 'GPU/CPU 升级在云中心完成',
    }
  },
  footer: {
    link: '了解更多',
    community: '关注社区',
    tip: '说明：社区包含 Twitter、telegram、reddit、medium、discord、tiktok、YouTube',
    text: 'DeepLink Portocol是去中心化的AI云游戏协议，结合AI、GPU、区块链技术提供超低延迟的游戏渲染技术，为3A游戏、云网吧、云电竞酒店等行业提供高性价比的云端游戏解决方案',
    meau1: '关于',
    meau2: '产品',
    meau3: '区块链',
    meau4: '重要空投活动',
    btn5: 'Windows SDK',
    meau3_text1: 'BSC链 DLC浏览器',
    meau3_text2: 'DBC链 DLC浏览器',
    meau3_text3: 'DBC主网钱包',
    meau4_text1: 'Bybit 30万U DLC空投',
    meau4_text2: 'Okx 36万U DLC空投',
  },
  reward: {
    title1: 'DeepLink 节点',
    desc1: '拥有获得奖励的机会，例如：DeepLink Token、皇冠NFT、社区治理的权力。',
    title2: '获取创世节点NFT',
    text1: 'DeepLink的网络用户越多，需要越多的计算和流量，创世节点为DeepLink网络提供基础设施，从而实现去中心化和帮助DeepLink更快速的发展。',
    text2: '通过购买创世节点NFT，您就可以因为对网络的贡献而获得奖励的机会。',
    text3: '这可能包括DeepLink Token、皇冠NFT、社区治理的权力。',
    text4_1: '有什么疑问吗？ 来',
    text4_2: '和',
    text4_3: '上面与我们一起交流。',
    btntext: '立刻购买',
    text5: '每售卖1000个创世节点NFT，价格增加1000USD',
    text6_1: '当前每NFT',
    text6_2: '支持节点数',
    text7: '创世节点NFT总数',
    text8: '永不增发',
    text9: '初始价格',
    text10_1: '每NFT支持',
    text10_2: '节点最大数',
    text11: '最高价格',
    text12: '什么是创世节点？',
    text13: '创世节点承担DeepLink网络计算和流量转发的核心任务，DeepLink通过挖矿的模式，让更多人可以参与获得收益。',
    text14: '总共有多少Token给到创世节点奖励？',
    text15: '币总数的30%，300亿枚DeepLink Token',
    text16: '创世节点NFT是什么',
    text17: '只有拥有创世节点NFT才能参与挖矿。',
    text18: '创世节点NFT售卖的收入怎么分配？',
    text19: '收益的50%投入到去中心化流动性奖励池（例如：Uniswap、Sushiswap的 DeepLink Token/USDC），50%为DeepLink运营公司收益。',
    text20: '具体的挖矿机制是什么？',
    text21: '一年总共奖励50亿，3年减半，DeepLink 皇冠NFT收益的10%回购DeepLink Token，每次减半前添加入总的奖励池，作为总的奖励50%Token，分3年奖励给所有节点。比如3年后，DeepLink 皇冠NFT收益的10%回购DeepLink Token为 120亿枚，则奖励池剩余总数变为：120亿+150亿，总共为270亿枚，第二个3年开始每年总奖励为270亿*50%*1/3=45亿。',
    text22: '创世节点机器有什么要求？',
    text23: '每个机器必须已经在DeepBrain Chain的网络中，DeepBrain Chain是一个去中心化的高性能算力网络，在深脑链中的机器已经质押了DBC，可以保证机器的稳定性。关于深脑链的详细了解：',
    text24: '持有创世节点NFT还有哪些其他好处？',
    details: {
      text1: '1.持有一定数量的创世节点NFT可获得一个国家的DeepLink NFT售卖总代理权(根据国家人口比例每300万人需要一个1个NFT)；',
      text2: '2.DeepLink Coin空投；',
      text3: '3.DeepLink孵化游戏项目代币空投；',
      text4: '4.投票上线游戏权限；',
      text5: '5.游戏NFT购买折扣；',
      text6: '6.云游戏版权和时长购买折扣；',
      text7: '7.其他参与社区治理权限。'
    },
    question: {
      text1_1: '什么是创世节点？',
      text1_2: '创世节点承担DeepLink网络计算和流量转发的核心任务，DeepLink通过挖矿的模式，让更多人可以参与获得收益。',
      text2_1: '总共有多少Token给到创世节点奖励？',
      text2_2: '币总数的10%，100亿枚DeepLink Token',
      text3_1: '创世节点NFT是什么',
      text3_2: '只有拥有创世节点NFT才能参与挖矿。',
      text4_1: '创世节点NFT售卖的收入怎么分配？',
      text4_2: '收益的50%投入到去中心化流动性奖励池（例如：Uniswap、Sushiswap的 DeepLink Token/USDC），50%为DeepLink运营公司收益。',
      text5_1: '具体的挖矿机制是什么？',
      text5_2: '一年总共奖励50亿，3年减半，DeepLink 皇冠NFT收益的10%回购DeepLink Token，每次减半前添加入总的奖励池，作为总的奖励50%Token，分3年奖励给所有节点。比如3年后，DeepLink 皇冠NFT收益的10%回购DeepLink Token为 120亿枚，则奖励池剩余总数变为：120亿+150亿，总共为270亿枚，第二个3年开始每年总奖励为270亿*50%*1/3=45亿。',
      text6_1: '创世节点机器有什么要求？',
      text6_2: '每个机器必须已经在DeepBrain Chain的网络中，DeepBrain Chain是一个去中心化的高性能算力网络，在深脑链中的机器已经质押了DBC，可以保证机器的稳定性。关于深脑链的详细了解：',
      text7_1: '持有创世节点NFT还有哪些其他好处？',
      text7_2: `1.持有一定数量的创世节点NFT可获得一个国家的DeepLink NFT售卖总代理权(根据国家人口比例每300万人需要一个1个NFT)；2.DeepLink Coin空投；
      3.DeepLink孵化游戏项目代币空投；4.投票上线游戏权限；5.游戏NFT购买折扣；6.云游戏版权和时长购买折扣；7.其他参与社区治理权限。`
    }
  },
  nft: {
    cont: {
      title1: '皇冠NFT',
      desc1: '拥有这个NFT，DeepLink软件的所有功能都可以有1年的使用权限。',
      desc2: 'NFT被购买后处于休眠状态，当需要使用此NFT时，可以在DeepLink软件中激活，当NFT被激活后',
      desc3: '开始进行倒计时，1年使用期限。DeepLink软件NFT收益的10%投入到节点奖励，',
      // desc4: '收益的40%投入到流动性奖励池，50%为DeepLink运营公司收益。',
      desc4: '收益的40%会被销毁，以提高DLC Token的价值',
    },
    enabl: {
      title1: 'NFT赋能',
      // desc1: 'DeepLink软件的所有功能有1年的使用权限',
      desc1: '拥有DeepLink软件的高级功能使用权限',
      desc2: 'DeepLink Coin空投',
      desc3: 'DeepLink孵化游戏项目代币空投',
      desc4: '投票上线游戏权限',
      desc5: '游戏NFT购买折扣',
      desc6: '云游戏版权和时长购买折扣',
      desc7: '其他参与社区治理权限'
      
    },
    edition: {
      title1: '专业版',
      title2: '团队版',
      tip: '皇冠NFT',
      btn: '立即购买',
      title3: '专业版 VS 团队版',
      desc: '具体功能使用权限',
      list: {
        li1_1: '内容',
        li1_2: '基础版',
        li1_3: '专业版',
        li1_4: '团队版',
        li2_1: '最优画质',
        li3_1: '转发机房',
        li3_2: '少量',
        li3_3: '大量',
        li4_1: '转发带宽',
        li4_2: '最大4M',
        li4_3: '最大50M',
        li4_4: '不限',
        li5_1: '端到端延时',
        li5_2: '最低<40ms',
        li5_3: '最低<20ms',
        li6_1:'同时可发起远控的设备数',
        li7_1:'设备数',
        li8_1:'远程桌面',
        li9_1:'远程摄像头',
        li10_1:'远程 SSH',
        li11_1:'隐私屏',
        li12_1:'虚拟显示器',
        li13_1:'游戏键鼠',
        li14_1:'多路串流',
        li15_1:'444真彩',
        li16_1:'录屏截屏',
        li17_1:'双屏对双屏',
        li18_1:'手柄',
        li19_1:'用户/设备分组管理',
        li20_1:'SSO单点登录',
        li21_1:'统一管理授权',
        li22_1:'批量部署',
        li23_1:'员工行为审计',
        li24_1:'水印',
        li25_1:'价格',
        li26_1: '单机器桌面会话数',
        li27_1: '文件传输',

        new_text1: '如果同一个钱包地址在多个机器同时登陆，则只能其中一台设备可以发起远程控制',
        new_text2: '一台机器只能打开两个远程控制的窗口',
      }
    }
  },
  internetCafe: {
    title1: 'DeepLink 云网吧解决方案',
    block1: {
      text1: '降低网吧主硬件投资60%以上',
      text2: '投资回本周期缩短40%',
      text3: '查看视频',
      text4: '下载文档',
    },
    title2: '云网吧NFT价格',
    block2: {
      desc: '收益40%投入到DEX交易所的DLC Coin池子中回报给DLC投资者',
      text1_1: '支持50台机器',
      text1_2: '1年3600 USDT',
      text2_1: '支持100台机器',
      text2_2: '1年7200 USDT',
      text3_1: '支持150台机器',
      text3_2: '1年10800 USDT',
      text4_1: '支持200台机器',
      text4_2: '1年14400 USDT',
    },
    title3: 'DeepLink全球招募云网吧代理商',
    block3: {
      text1_1: '权利',
      text1_2: '低于市场价格获得云网吧NFT',
      text2_1: '责任',
      text2_2: '在代理所在区域寻找云网吧合作伙伴，合作伙伴有能力给网吧提供云网吧服务',
      text3_1: '资格评判标准',
      text3_2: '需要有所在区域的网吧行业相关资源，需要持有代理区域人口等量的DLC Coin',
    },
    title4: '云网吧Q&A',
    block4: {
      text1_1: '什么是云网吧？',
      text1_2: '云网吧就是在网吧中只需要配置显示器和mini pc，不需要配置高端GPU，然后通过网络连接到远程GPU服务器，玩家在网吧可以随意选择使用各种类型的GPU服务器，网吧也无需管理游戏资源，网吧只需要根据玩家使用时长来进行付费。',
      text2_1: '怎么样申请成为代理商？',
      text2_2: '可以通过加入Telegram联系我们：',
      text3_1: '代理商可以获得哪些收益？',
      text3_2: '代理商具有所在区域的独家权利，代理商通过发展云平台，来服务网吧，从每个NFT中赚取差价',
      text4_1: '代理商是否需要与DeepLink官方签订协议?',
      text4_2: '是的，需要签订协议',
      text5_1: '云网吧NFT收益对DLC的价格有什么影响？',
      text5_2: '云网吧NFT收益中的40%会放入去中心化交易所比如Uniswap中的DLC-USDT交易对，用来购买DLC，购买到的DLC将会被销毁',
      text6_1: '怎么保证云网吧NFT收益购买DLC是透明的？',
      text6_2: '可以链上查询到云网吧NFT的发行数量，通过发行数量可以计算到NFT的收益',
    },
    title5: '云网吧合作伙伴'
  },
  gameFi: {
    title1: '云GameFi平台',
    block1: {
      text1: '云GameFi平台',
      text1_1: '任何用户都可以通过DeepLink无需下载玩GameFi游戏',
      text1_2: '不受任何国家对游戏下载的限制，无需在Apple Appstore下载',
      text1_3: '帮助GameFi游戏厂商拓展更多用户',
      text2: '游戏厂商合作方式',
      text2_1: '广告合作',
      text2_2: '流量合作',
      text2_3: '游戏分成',
      text2_4: 'NFT合作',
    },
    title2: 'GameFi Q&A',
    block2: {
      text1_1: '什么是云GameFi平台？',
      text1_2: 'DeepLink会挑选优质游戏合作方，将游戏加入到DeepLink云平台中，这样用户无需下载游戏，就可以直接通过DeepLink玩GameFi游戏',
      text2_1: '云GameFi平台支持手机游戏吗？',
      text2_2: '我们将会支持PC来玩手机游戏，且可以同时开启多个游戏窗口',
      text3_1: 'GameFi厂商和DeepLink合作有哪些好处？',
      text3_2: 'DeepLink会帮助合作厂商带来品牌爆光，带来更多游戏玩家',
      text4_1: 'GameFi厂商是否需要与DeepLink官方签订协议?',
      text4_2: '不需要，DeepLink会筛选优质合作伙伴,通过筛选的游戏将会自动上架到DeepLink平台',
      text5_1: '怎么申请和DeepLink合作？',
      text5_2: '申请邮箱：',
    }
  },
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: '去中心化的AI云游戏协议',
      title2: 'DeepLink Protocol让云游戏开发从未如此简单',
      btn1: '进入PinkSale',
      btn2: '下载白皮书',
      btn3: '下载Pitch Deck',
      btn4: 'DeepLink Public Sale',
      btn5: '欢迎来到节点奖励',
      btn6: '下载客户端',
      desc1: '支持Deeplink 获得DLC Token',
      desc2: '100% Secure & Verified',
      desc3: 'NVIDlA Inception Program'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: '展示的效里是两台电脑举例50公里，控制端是普通的笔记本，被控制端是高端的GPU机器，显示一个游戏动态画面，动态图的形式大概3-5秒',
      btn: '播放视频了解更多'
    },
    cont3: {
      text1: '超低延迟',
      text2: '高分辨率',
      text3: '超低延迟',
      text4: '不卡顿、不断开,高稳定',
      desc1: 'DeepLink Protocol 实现革命性技术突破，带来无与伦比的完美体验',
      desc2: '核心技术团队成员从2011年进入云游戏行业,有来自包括来自Cisco、Intel、Nvidia、华为、顺网等公司,在10余年研发积累的基础上，DeepLink才诞生'
    },
    cont16: {
      title1_1: 'DeepLink Protocol为云游戏提供超低延迟的技术服务',
      title1_2: '为云游戏提供超低延迟的技术服务',
      title2: '目前基于DeepLink Protocol开发的产品有如下，还有超过20款应用正在开发中',
      text1: '远程控制',
      text2: '云游戏 & 云游戏平台',
      text3: '云网吧',
      text4: '云XR',
      text5: 'XXX 云VR游戏',
      text6: 'YYY 云游戏平台'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: '以远程控制工具为流量入口',
      title2: 'DeepLink软件以远程控制工具为流量入口，云游戏平台和云网吧为变现手段',
      text1_1: '900,000+',
      text1_2: '月活跃用户数',
      text2_1: '110+',
      text2_2: '提供服务的国家数量',
      text3_1: '2,500,000+',
      text3_2: '用户总数',
      text4_1: '300,000+',
      text4_2: '日活跃用户数',
      desc1: '远程控制工具',
      desc2: '云游戏和云XR',
      desc3: '云网吧',
      btn1: 'YouTube KOL视级1',
      btn2: 'YouTube KOL视级2',
      btn3: 'YouTube KOL视级3',
      btn4: 'YouTube KOL视级4',
    },
    cont17: {
      title1: 'DeepLink Protocol 的商业模式',
      text1: '佣金模式',
      text2: 'License模式',
      desc1: '每笔云游戏交易DeepLink Protocol会收取20-30%的佣金，佣金用DLC结算，且100%DLC会实时销毁',
      desc2: '云网吧和云电竞酒店类的应用，每个应用每个电脑终端每月支付6美金的License费用',
    },
    cont5: {
      title1: '通过区块链技术让矿工提供闲置网络流量',
      desc1: '节点接入',
      desc2_1: '设备需要',
      desc2_2: '流量转发',
      desc3: '招盖全球的矿工通过购买NFT提供流量节点获得DLC代币奖励',
      desc3_2: '',
      title2: '流量节点提供端',
      text2: '矿工提供流量节点', 
      title3: '用户端',
      text3: '个人用户'
    },
    cont6: {
      title1: '极大增强DeepLink市场竞争力',
      title2: '通过区块链技术',
      desc1: '通过智能合约进行质押数字货币 保证GPU提供者提供稳定的服务',
      desc2: '每个人都可以给网络提供贡献获得回报 比如提供GPU服务器、提供流量节点',
      desc3: '引入矿工提供流量转发节点，可以实现节点数量广泛分布且不需要中心化公司投入机器部署节点，更加去中心化且公司0服务器成本',
      desc4: '通过智能合约进行质押数字货币 投资者无需等待5-10年， 1年左右可以退出产生回报',
      desc5: '用户可以参与到项目增长的 红利中有利于扩大传播',
      desc6: '用户实现无国界 自由支付',
      text1: '质押',
      text2: '人人参与', 
      text3: '零成本',
      text4: '缩短回报时间',
      text5: '无国界', 
      text6: '用户获得红利'
    },
    cont7: {
      title1: '我们正在进行一场Web3技术对Web2技术的降维打击',
      desc1: '云游戏达到低延迟的良好体验，除技术以外，还需要GPU机器需要距离用户50公里范围内，全球覆盖需要超过5万个数据中心',
      title2: '传统中心化的云游戏平台',
      title3: 'DeepLink基于区块链技术的云游戏平台',
      text1: '平台自己采购机器，成本巨大，回本遥遏无期',
      text2: '机器覆盖窄，无法全球覆盖', 
      text3: '中心化技术无法支撑数以万的数据中心追接',
      text4: '矿工和网吧主提供GPU服务器，且有代币奖励矿工回本周期快，且平台没有采购机器的压力',
      text5: '矿工和网吧数量多，可以全球覆普', 
      text6: '分布式的区块链技术支持超过10万的数据中心超过1000万的GPU服务器书点'
    },
    cont8: {
      title1: 'DeepLink Token应用场景',
      text1: '购买DLC',
      text2: '支付DLC', 
      text3: '支付USDT',
      text4: '支付DLC',
      text5: '注入DLC和USDT到DEX', 
      text6: '出售DLC',
      text7: '用户1',
      text8: 'DeepLink软件', 
      text9: '其中40%DLC会在链上直接销毁',
      // text10: '1.购买NFT',
      // text11: '2.购买云游戏时长', 
      // text12: '3.租用网吧机器时长',
      // text13: '4.购买个人共享游戏时长', 
      text10: '1.购买NFT',
      text11: '2.购买云游戏时长', 
      text12: '3.租用网吧机器时长',
      text13: '4.购买个人共享游戏时长', 
      text14: '其中40%USDT会在DEX回购DLC然后销毁', 
      text15: '购买NFT',
      text16: 'DEX 和 CEX',
      text17: '其他交易平台',
      text18: 'Miner',
      text19: '流动性挖矿奖励',
      text20: '奖励2年，每年50亿DLC'
    },
    cont8_new: {
      text7: '个人用户',
      text12: '3.网吧机器租用时间',
      text21: '商业用户',
      text22: 'DeepLink 协议',
      // text23: '1.购买许可证',
      // text24: '2.10%-20%佣金',
      text23: '1.购买许可证',
      text24: '2.20-30%的手续费',
      text25: '100%的DLC将直接在链上销毁',
    },
    cont8_new1: {
      title: '顶级 YouTubers 支持我们的项目',
      text1: '',
      text2: 'Business users',
      text3: 'DeepLink Protocol',
      text4: '1.购买许可证',
    },
    cont9: {
      title1: 'DeepLink Token 流动性挖矿奖励',
      text1: '用户1',
      text2: '注入DLC和USDT到DEX', 
      text3: '流动性挖矿奖励',
      text4: '奖励2年，每年50亿DLC',
      text5: '在Uniswap和Pancake Swap中注入DLC和USDT流动性', 
      text6: '每天发放一次奖励，通过流动性NFT自动领取',
      text7: '其中80%奖励给流动性提供者，20%奖励给交易者',
      text8: '每天奖励13,698,630个DLC'
    },
    cont9_new: {
      title1: 'DeepLink 代币流动性挖矿奖励',
      text1: '5% 的 DLC 以及等值的 USDT、ETH 和 BNB。',
      text2: '注入 DEX',
      text3: '增强 DEX 的流动性',
      text4: '',
      text5: '这 5% 的代币将在 IDO（初始 DEX 发行）期间出售。基于实际出售的比例，最终将全部注入到 DEX 中',
      text6: '将 DLC 和 USDT 的流动性注入到 Uniswap、SushiSwap、Pancake Swap 等 DEX 中',
      text7: '这部分流动性将被锁定 5 年，之后将用于项目的长期发展',
      text8: ''
    },
    cont10: {
      title: 'Roadmap&计划',
      desc1: '2020年10月',
      text1: '启动DeepLink产品立项',
      desc2: '2021年3月',
      text2: '获得种子轮投资',
      desc3: '2021年8月',
      text3: '发布第一个DeepLink内部开发版本，技术延迟达到3ms，支持游戏键盘和鼠标',
      desc4: '2022年5月',
      text4: '发布第一个DeepLink远程控制功能测试版本，支持3K 144HZ显示',
      desc5: '2022年8月',
      text5: '发布第二个DeepLink远程控制功能测试版本，支持虚拟显示器',
      desc6: '2022年10月',
      text6: '推出DeepLink的正式版本，支持韩语、中文和英语，并在新加坡成立DeepCloudX公司来运营DeepLink',
      desc7: '2022年12月',
      text7: '获得天使轮投资',
      desc8: '2023年4月',
      text8: '和韩国第一大连锁网吧合作，在网吧搭建真实环境样板间，进行云网吧测试，取得用户好评',
      desc9: '2023年6月',
      text9: 'DeepLink下载量超过200,000，用户遍布100个国家，支持8K 60Hz显示',
      desc10: '2021年1月',
      text10: '确定DeepLink研发方向，并开始进行产品设计和研发',
      desc11: '2021年4月',
      text11: '核心团队组建完毕，核心团队成员从2011年进入云游戏行业, 有来自包括来自Cisco、IntelNvidia、华为、顺网等公司创始团队',
      desc12: '2022年3月',
      text12: 'DeepLink开始应用网吧领域进行测试，技术延迟认到1ms',
      desc13: '2022年7月',
      text13: '发布第三个DeepLink远程控制功能测试版本，支持隐私屏、远程拷贝文件',
      desc14: '2022年9月',
      text14: '发布第四个DeepLink远程控制功能测试版本，支持多路串流、支持444真彩',
      desc15: '2022年11月',
      text15: '在首尔搭建10台mini pc机器和显示模拟网吧环境进行云网吧测试',
      desc16: '2023年2月',
      text16: 'DeepLink突破10万下载量，用户夏盖50个国家，支持4K 200HZ显示',
      desc17: '2023年5月',
      text17: 'DeepLink突破15万下载量，用户覆盖80个国家，支持DLC钱包功能、支持越南语、日语'
    },
    cont11: {
      title: '发展计划',
      desc1: '2023年7月',
      text1: '支持添加设备列表和分享奖励功能',
      desc2: '2023年8月',
      // text2: '支持NFT功能，在首尔合作的第一家云网吧正式开始运营',
      text2: '在首尔推出第一家云网吧',
      desc3: '2023年10月',
      // text3: '支持个人电脑和网吧电脑共享，目标DeepLink突破40万下载量',
      text3: '支持分享奖励功能',
      desc4: '2023年12月',
      // text4: 'Gamefi平台上线，支持阿拉伯语、德语、法语、西班牙语、泰语、土耳其语',
      text4: '支持NFT功能',
      desc5: '2024年Q1',
      text5: '为WEB版本支持游戏共享功能，并建立合作伙伴关系，目标是DeepLink的下载量超过50万次',
      desc6: '2024年Q2',
      text6: '目标是DeepLink的下载量超过100万次，并与10家云网吧合作',
      desc7: '2024年Q3',
      text7: '目标是DeepLink的下载量超过200万次，配合3000台共享计算机和50家云网吧的合作',
      desc8: '2024年Q4',
      text8: '支支持iOS、Mac、Vision Pro AR版本，目标是DeepLink的下载量超过300万次，10000台共享计算机，与150家云网吧建立合作关系',
      desc9: '2025年',
      text9: '支持多品牌的VR和AR设备，目标是DeepLink的下载量超过600万次，20000台共享计算机，与300家云网吧建立合作关系',
      desc10: '2026年',
      text10: '目标是DeepLink的下载量超过1500万次，50000台共享计算机，与800家云网吧建立合作关系。'
    },
    cont12: {
      text: 'DBC链上发行600亿，ETH链发行300亿，BSC发行100亿',
      text1: '融资',
      text1_1: '种子轮',
      desc1_1: '上CEX交易所后30天开始解锁，分20个月，每月解锁5%',
      text1_2: '天使轮',
      desc1_2: '上CEX交易所后30天开始解锁，分10个月，每月解锁10%',
      text1_3: 'A轮',
      desc1_3: '上CEX交易所后30天开始解锁，分10个月，每月解锁10%',
      text1_4: 'PreSale and Public Sale',
      desc1_4: '上市后40%立即解锁，其余将从8月30日开始解锁，解锁期为6个月，期间每月解锁10%。筹资金额的10%到30%将加入到DEX流动性中（锁定3年）',
      text2: '团队',
      desc2: '在CEX交易所上市后60天开始解锁，解锁期为20个月，每月解锁5%。',
      text3: '社区空投',
      desc3: '满足一定任务要求，DID KYC认证后可以获得空投',
      text4: 'NFT售卖',
      desc4: 'NFT绑定软件license权并且赠送TOKEN，Token在上CEX交易所后开始解锁',
      text5: '挖矿',
      desc5: '16%的份额用于奖励提供GPU算力的矿工，另外4%用于带宽挖矿，这部分每4年减半一次。挖矿将在CEX交易所上市后30天开始。',
      text6: '基金会',
      desc6: '代币将在CEX交易所上市后60天开始解锁，总解锁期为2年，期间每季度解锁12.5%。',
      text7: '顾问以及法律',
      desc7: '用于解决不同国家的法律问题以及咨询顾问的奖励，解锁将在CEX交易所上市后30天开始，分为10个月进行，每月解锁10%。',
      
      text8: '总计',
      text9: '数量（十亿）：',
      text10: '描述：',
      text11: '流通中（十亿）：',
      text12: '未流通（十亿）：',
      text13: '1000亿',
    },
    cont13: {
      title: '团队和顾问',
      intro1: 'DeepLink CEO',
      intro2: 'DeepLink的联合创始人',
      intro3: 'DeepLink的首席运营官（COO）',
      intro4: 'DeepLink的首席商务官（CBO）兼印度市场领导',
      intro5: '顾问',
      intro6: '顾问',
      intro7: '顾问',
      intro8: '联合创始人兼首席营销官',
      user1_1: '连续创业者，拥有13年人工智能经验，7年区块链和分布式计算网络技术开发经验。',
      user1_2: '2008年毕业于中国海洋大学计算机科学专业。',
      user1_3: '2012年，他创立了聚尚智能，并开发了世界上第一个中文AI语音助手“智能360”。',
      user1_4: '在三年内，该软件的下载量超过了1700万。2014年，他推出了世界上第一个AI音箱“小智音箱”。',
      user1_5: '2017年5月，他创立了DeepBrainChain，服务于数百个AI、云游戏和云网吧客户。',
      user2_1: '连续创业者，资深云游戏专家。曾任顺网云游戏研究院院长，负责技术研发。',
      user2_2: '1999年毕业于浙江大学计算机科学专业。2001至2004年在浙江兰德参与电信相关工作。',
      user2_3: '2009年，他创立了中国首款双核浏览器“向日葵浏览器”。',
      user2_4: '2010至2021年加入上市公司顺网并创立顺网云电脑，担任顺网研究院院长。他提供云网吧解决方案并运营云游戏，使其公司成为中国首家提供云游戏解决方案的公司。',

      user3_1: '教育背景：2007年北京大学中文系',
      user3_2: '2023年：DeepLink的首席运营官',
      user3_3: '2022-2023年：DeepBrain Chain首席执行官',
      user3_4: '2019-2021年：CELLOGIN海外市场领导',
      user3_5: '2018年：FLOW FACTORY首席执行官',

      user4_1: '教育和证书： - 2020年在迈索尔大学获得工商管理学士学位。',
      user4_2: '- 数字营销认证。',
      user4_3: '- 搜索引擎优化（SEO）认证。',
      user4_4: '- 区块链认证。',
      user4_5: 'Technic Constructions的销售与市场营销： - 2019年担任销售与市场营销角色，三个月内实现50万美元的销售。',
      user4_6: "BYJU'S的业务发展助理： - 2020至2021年就职。",
      user4_7: '- 在价值100亿美元的独角兽初创公司中，发挥关键作用，推动增长，建立合作关系，并简化教育技术计划的运营。',
      user4_8: 'Space Inn的联合创始人： - 联合创立一个独立项目，专注于开发创业技能、战略思维和商业敏锐度。',
      user4_9: '股票市场投资/交易： - 在股票市场投资和交易方面积累了五年的经验，展示了在金融市场中导航和利用投资机会的专业知识。',
      user4_10: '企业公司的实习： - 在多家企业公司完成了多次实习，获得了有关企业文化、高效流程和有效业务策略的宝贵见解。',
      user4_11: '加密行业经验： - 参与了多个加密和NFT项目，获得了宝贵的行业经验。',
      user4_12: '- 为Empowa的一项重要Real-Fi Cardano项目贡献力量，推动其发展和增长。2022年为Cardano项目Empowa贡献，促进其增长。',
      user4_13: '- 目前与DeepLink Cloud合作，扩展在加密领域的专业知识。',

      user5_1: '自2012年起，在Hyunjin ICT担任副总裁，该公司是一家物联网和通信服务公司，已有11年。',
      user5_2: "销售和市场管理，有在公共机构执行多个大型项目的经验。",
      user5_3: "在韩国最大的IT公司Samsung SDS工作了14年（19982012），从事信息战略咨询、业务创新项目和软件开发。",
      user5_4: "在Coinzest加密货币交易所工作了3年（20172019），有在韩国排名第一、全球排名第十的经验。",
      user5_5: "Hyunjin Venus的创始人，该公司为韩国大型企业和公共机构运营福利购物中心，已经运营了5年。",
      user5_6: '具有PMP（项目管理专业人员）和CISA（注册信息系统审计师）资格。',

      user6_1: '教育背景：2003年北京大学光华管理学院',
      user6_2: "2023年：MetABC首席执行官",
      user6_3: "2020年：HillstoneHub首席执行官",
      user6_4: "2019年：Hillstone Business Center首席执行官",
      user6_5: "2019年：Hillstone Global（区块链）首席运营官",
      user6_6: "2017年：Foundersbridge创始人",
      user6_7: "2017年：Hillstone Partners（私募股权）合伙人",
      user6_8: "2014年：BrightA Consulting联合创始人",
      user6_9: "2012年：高级顾问（中国业务专家）",

      user7_1: "Joseph Alexander是Deep Brain Chain定制的大型语言模型ChatDBC.com的创造者。",
      user7_2: '他的专长是DeepLink和DBC的北美业务发展。',
      user7_3: "您可以在Twitter上关注{'@'}ChatDBC，以获取所有DeepLink和DBC的最新消息。",

      user8_1: "Maryna Barysheva 是屡获殊荣的 Web3 营销机构 LKI Consulting 的首席运营官，专门从事深度技术、DeFi、基础设施和游戏项目。",
      user8_2: "她成功扩展了 23 多个国家/地区的区块链项目，管理了 10 万至 1000 万美元的营销预算，并为一家价值 1 亿美元的 B2B 科技初创公司进行营销。",
      user8_3: "Maryna 的专长在于社区发展、品牌知名度和全球 GTM 战略。",
      user8_4: "她是一位国际公共演讲者，经常出现在世界各地的加密货币会议上，也是赫尔辛基大学人工智能、技术和区块链项目的孵化器导师。",

      user9_1: "Brando 是加密领域的资深人士。2016 年，他开始研究加密货币，这导致了著名的 2017 年牛市。",
      user9_2: "他在 2012 年创立了 Bigger Than Race™️，并在 2017 年将其更名为今天的样子。",
      user9_3: "他的资质包括通过 EveryRealm 获得的 Web3/Metaverse 认证，EveryRealm 是一家风险投资基金，也是 Metaverse 创新和开发的领导者，以及通过 IBM 获得的区块链和比特币认证。",
      user9_4: "‘加密奇才’ BRANDO MURPHY ",
      user9_5: "他的主要愿景和目标是帮助人们通过区块链技术实现财务自由，并能够投资和拥有第四次和第五次工业革命新经济的基础设施。BTR 社区 VC 正在帮助全球忠实会员拥有未来的技术。",
      user9_6: "2024 年，他成为 DeepLink Protocol 的联合创始人兼首席营销官，DeepLink Protocol 是一种人工智能云游戏技术，为全球游戏玩家提供公平的竞争环境。加利福尼亚州洛杉矶是他的故乡。",


      
      user10_1: "Summer 是 FireHustle 的创始人和主持人，FireHustle 是一家媒体公司，内容涵盖加密项目、投资策略等。Summer 拥有商业教育背景，在广播和通信领域拥有丰富的经验。",
      user10_2: "在媒体方面，其资产组合包括一个拥有 44,000 多名订阅者的 YouTube 频道、一个拥有 3,000 多名成员的专用 Discord 社区、一个私人会员组等。",
      user10_3: "在投资方面，FireHustle 个人投资了 50 多家 web3 初创公司，并担任许多加密项目、风险投资公司、启动平台、辛迪加、KOL 团体等的营销、筹款和咨询合作伙伴。",
    
      user11_1: "Manny 是一位区块链爱好者和专家，拥有十多年的行业经验，自 2013 年以来一直为领先的公司和计划做出贡献。",
      user11_2: "他的职业背景包括：Grayscale 的基础职位、Coinbase 的运营团队、DYDX 和 Ledger 的营销团队，以及最后在 Binance US 的职位。",
      user11_3: "学历：美国知名机构（尤其是斯坦福大学）的区块链和加密货币相关课程，包括区块链和加密货币：全面介绍、高级区块链策略、首席执行官计划和首席营销官计划。他拥有金融和工商管理专业。",
    },
    cont14: {
      title: '我们的投资机构',
      organ1: 'DeepBrain Chain 基金会成立于2017年。2018年1月，DBC代币在火币交易所上市。主网于2021年启动。DeepBrain Chain是一个以GPU为核心的高性能分布式计算能力网络。其愿景是基于区块链技术构建一个无限扩展的分布式高性能计算能力网络，成为AI+元宇宙时代最重要的计算能力基础设施。它主要为人工智能、云游戏、电影渲染等领域提供GPU计算服务。目前，它已为数百家人工智能和云游戏相关公司以及超过30,000名AI从业者提供服务',
      organ2: "高毕资本是一家专业的风险投资公司，总部位于上海、北京和东南亚。他们专注于中国早期科技项目的投资。高毕基金的战略投资者包括IBM、Sierra Ventures、麦格劳希尔公司和Steamboat Ventures（迪士尼的风险投资部门）。他们曾投资于途牛、Camera360和筑云等知名初创企业。高毕资本是行业内的知名投资基金",
      organ3: "Hycons是基于DBC技术开发的GPU计算云平台，由Hyunjin ICT运营。Hycons凭借基于DBC的分布式高性能网络技术和基于DeepLink的无延迟高清流媒体技术脱颖而出。该平台为企业、学校、研究机构和特许经营者等不同服务类型提供包括会员管理、注册和支付在内的全面服务。目前在韩国运营着超过1,000张GPU卡和2个中心，未来计划扩展到5个中心。此外，Hycons还在通过应用到互联网咖啡馆的特许经营业务进行测试。凭借先进的技术基础、竞争力的云服务费率和自动化服务能力，Hycons旨在成为世界领先的GPU计算平台",
      organ4: 'ROCK Capital是一家成立于2018年的专业加密货币基金公司，总部位于韩国，汇聚了金融投资以及区块链和去中心化专家的专业资源。与各种国内外企业、全球项目、政府、机构及其他经济实体进行广泛合作，成功执行了超过100个项目，开拓了未来金融的多个领域。通过加强合作、增长、激活和伙伴关系，ROCK在多样化的市场环境中拥有丰富的经验和专业知识。坚持不移的原则如磐石，ROCK致力于产生高回报、扩大投资机会，并最大化资本活动，创造具体的客户体验',
      organ5: '专注于新兴技术的去中心化区块链，将推动第四和第五次革命。该风险投资公司拥有来自各行各业不同存在的丰富知识，这有助于他们抓住整个web3空间及其以外的机会。他们与Bloktopia、Animoca Brands、Stripe等知名品牌共同投资。他们与奇点网和Shiba生态系统以及各种游戏工作室建立了稳固的关系。他们的风险投资公司是基层的，持有BTR-Army通行证的人可以获得其服务'
    },
    cont15: {
      title: '社区活动',
      name1: '越南MARVELS HCMC DeepLink',
      name2: '越南Web3活动演讲',
      name3: 'Deeplink香港活动',
      name4: 'IIITB 印度大学 DeepLink',
      name5: 'IIT Sri City 印度大学 DeepLink',
      name6: 'IIT Tirupati 印度大学 DeepLink',
      name7: 'NIT Warangal 印度大学 DeepLink',
      name8: 'DeepLink新加坡活动',
      name9: 'DeepLink新加坡活动',
      name10: 'Vietnam ESPORTS3.0 EVENT',
      name11: 'DEEPLINK Investor Day in Seoul, South Korea',
      name12: 'DEEPLINK Investor Day in Tokyo, Japan',

      name13: 'DEEPLINK in the TEAMZ/WEB3 SUMMIT, Tokyo Japan',
      name14: 'DEEPLINK Sponsors the HTX Dubai Whale Night',
      name15: 'DEEPLINK at the Lbank Dubai Night',
      name16: 'DEEPLINK Sponsors the HOT Crypto Twist with MTCAPITAL and Tido Capital',
      name17: "DEEPLINK and Animoca's Dubai Game Night",
      name18: 'DeepLink at the Token2049 Dubai afterparty',
      name19: "DEEPLINK's presentation at BYBIT event",
      name20: "DeepLink participated in the 2024 Tokyo Blockchain Expo",
      name21: 'Brando, Advisor to DeepLink, Presents Keynote Address at Depin House Consensus Conference Hosted by IOTX',
      name22: "DeepLink Principal Consultant is attending the Investor Conference Forum in Austin, USA",
      name23: "DeepLink’s Cornerstone Investor Mr. Adam Ha, participated in the DEPIN ALLIANCE YACHT PARTY ⛵️panel and introduced DeepLink to the Vietnam market",
      name24: "We co-organized a blockchain event in Seoul with {'@'}DeepBrainChain and ETNEWS, Korea's top IT news media.",
      name25: "DeepLink Protocol Successfully Participates in Stanford 'The Future of Decentralized AI' Summit.",
      name26: "Team leaves yet another foot print in Tokyo, Japan at the  {'@'}13Desk Meet Up",
      name27: "The turn up was great in Japan as always. Thank you for the continued support.",
      name28: "🚀 Exciting moments captured at InnoBlock Singapore Web3 Token2049 Week",
      name29: "🚀 DeepLink is proud to be part of the sponsors for The Carnival organized by {'@'}okxweb3 The World's Leading OKX Assets Platform.",
      name30: "🥳Check a sneak peak of the Seraph with Friends event in Dubai😍",
      name31: "🌐DeepLink Protocol showcased its cloud gaming & esports tech at the Korean National Internet Cafe Association conference, engaging with 80% of 🇰🇷 internet cafe owners. ",
      name32: "{'@'}DeepLinkGlobal has an upcoming Massive Airdrop Event on Binance Wallet. {'@'}Web3WithBinance🎉",
    }
  },
  homeNew: {
    cont1:  {
      text1: '重新定义远程游戏',
      text2: '无延迟的远程控制，在DeepLink即可实现',
      text3: '现在只支持Windows环境'
    },
    cont2: {
      title: '远程控制介绍',
      block1: {
        title: '什么是远程控制',
        text: '通过互联网将不同位置的电脑或者机器进行远程控制的行为'
      },
      block2: {
        title: '远程控制类型',
        text: '远程控制分为基于客户端/服务器的远程控制和P2P点对点远程控制'
      },
      block3: {
        title: '远程控制的功能',
        text: '您可以在多台电脑之间共享文件，帮助确保远程教育安全等等'
      },
      block4: {
        title1: 'DeepLink',
        title2: '远程控制的优点',
        text: '通过区块链技术，解决分散问题，并提供电竞级超低延迟无中断的最佳游戏体验'
      }
    },
    cont3: {
      title: '传统的远程控制工具使简单的任务也难以完成',
      text1: '连接延迟',
      text2: '频繁断掉',
      text3: '帧率下降',
      text4: '昂贵的费用'
    },
    cont4: {
      title1: '现在就像控制本地电脑一样',
      title2: '顺畅!',
      desc: '通过智能合约技术解决分布式问题，体验快速、强大的远程控制功能',
      text1: '简单快捷的访问',
      text2: '支持8K',
      text3: '高性能游戏最优化',
      text4: '无中断',
      text5: '工作工具最优化',
      text6: '免费使用'
    },
    cont5: {
      title1: '强烈且顺畅的',
      title2: '远程游戏',
      title3: '无论何时何地都可以使用',
      text1: '战地突击',
      text2: '英雄联盟',
      text3: '无畏契约'
    },
    cont6: {
      title1: '沉重且昂贵的游戏笔记本电脑',
      title2: '而DeepLink',
      title3: '轻巧且经济实惠',
      title4: '',
      text1: '至少10000元',
      text2: '最少1.6Kg以上',
      text3: '高性能笔记本',
      text4: '0元'
    },
    cont7: {
      title1: '各种使用方法',
      title2: '用途非常广泛',
      block1: {
        text1: '外部',
        text2: '工作用',
        text3: '笔记本'
      },
      block2: {
        text1: '办公室，家',
        text2: '高性能',
        text3: '台式机器',
        text4: '高性能工作',
        text5: '高性能游戏'
      },
      block3: {
        text1: '客户',
        text2: '共同合作',
        text3: '远程办公'
      },
      title3: '支持强大技术的同时',
      title4: '确保全面安全',
      block4: {
        text1: '账户安全',
        text2: '基于区块链技术的',
        text3: '多重账号保护机制'
      },
      block5: {
        text1: '连接安全',
        text2: 'DTLS1.2协议保护'
      },
      block6: {
        text1: '数据安全',
        text2: '256 BIT AES 加密'
      },
      block7: {
        text1: '机器安全',
        text2: '可设置用户权限'
      }
    },
    cont8: {
      title1: '使用DeepLink',
      title2: '随时随地连接',
      text1: '只要有节点的地方，就能远程无缝玩高性能游戏。连接您的区块链节点计算机，在任何国家或地点畅玩在线高性能游戏',
      title3: '灵活使用DeepLink',
      title4: '协同工作',
      text2: 'DeepLink的超低延迟远程连接可实现更强大的协作。专为设计师、策划师和开发人员而最优化开发'
    },
    cont9: {
      title1: '不仅仅是游戏',
      title2: '更是为员工提供生产力工具'
    },
    cont10: {
      title1: '与DeepLink一起',
      title2: '摆脱空间和技术限制',
      block1: {
        name: 'Hyun Choi',
        desc: 'IT运营和远程维护经理',
        text1: '远程工作支持无延误',
        text2: '提高了客户满意度'
      },
      block2: {
        name: 'Sangwo Baek',
        desc: '游戏玩家',
        text1: '现在只需一台销售笔记本电脑，就能在出差路上玩高性能游戏',
        text2: '我从未想过有这样的可能'
      },
      block3: {
        name: 'Junho Kim',
        desc: '销售代表',
        text1: '没有设备限制',
        text2: '我只用笔记本电脑就能开展业务'
      },
      block4: {
        name: 'Min Kyung Yoo',
        desc: '远程工作设计师',
        text1: '我可以随时随地工作，无需下载',
        text2: '连接总部电脑进行工作'
      },
    },
    cont11: {
      title: '什么是DeepLink钱包',
      text1: '安全的账户保护系统',
      desc1: 'DeepLink 钱包采用区块链技术、256 位 AES 加密和基于设备信息的多层账户保护机制，支持稳健安全的账户保护系统',
      text2: '安全交易和使用代币',
      desc2: '与通过智能合约和点对点加密货币转账进行的交易相比，钱包可实现更安全的代币交易和使用',
      text3: '全球各地都可以使用 DeepLink',
      desc3: '只要您拥有钱包私人密钥，您就可以在全球任何地方登录您的私人钱包，通过DeepLink玩游戏'
    },
    cont12: {
      title1: '超低延迟远程游戏',
      title2: '立即开始DeepLink!',
      text1_1: '请下载',
      text1_2: 'DeepLink 客户端',
      text2_1: '注册DeepLink会员',
      text2_2: '需要生成个人钱包',
      text2_3: '',
      text3_1: '钱包生成后',
      text3_2: '访问所需设备并开始DeepLink',
      text3_3: ''
    }
  },
  cloudInternet: {
    title: '如何成为云网吧解决方案商',
    text: '目前全球主要网吧分布国家有韩国（6000家），中国（4万家），巴西（1万家），（印尼）2万家、菲律宾（2万家）、越南（1.5万家），土耳其（5000家），还有印度、墨西哥、中东、南美、非洲、欧美等等国家，全球一共加起来网吧数量超过15万家，但是网吧行业已经有20年的历史，已经变成一个传统行业，回本周期已经上升到3-4年时间，而且面临着各种风险，比如新冠疫情曾让大量网吧倒闭，所以网吧行业需要一种全新的解决方案，可以降低回本时间，降低风险，提高运营网吧的效率，云网吧解决方案由此诞生',
    cont2: {
      text1: '什么是',
      text2: '云网吧解决方案？',
      text3: '云网吧是一种新型的网吧服务模式，拥有超低延迟渲染技术。在云网吧中，游戏和应用程序不是直接安装在本地电脑上，而是托管在云端GPU服务器上。用户通过云端服务访问和玩游戏，而不需要高性能的本地硬件。',
      block1: {
        text1: '减少本地硬件需求',
        text2: '由于游戏和应用程序运行在云端GPU服务器上，因此本地电脑不需要高性能的GPU或大量存储空间。这降低了网吧的硬件投资和维护成本'
      },
      block2: {
        text1: '即时访问最新游戏',
        text2: '用户可以即时访问最新的游戏，无需等待下载和安装。所有游戏更新和维护都在云端进行，为用户提供了便利和高效的体验'
      },
      block3: {
        text1: '高性能游戏体验',
        text2: '本地显示器只需要配置mini pc（100美金左右），云网吧也能提供高性能的游戏体验。因为游戏的运算工作是在远程高性能GPU服务器上完成的'
      },
      block4: {
        text1: '节省空间和能源',
        text2: '较低配置的本地电脑占用更少的空间，并且消耗更少的电力，有助于降低网吧的运营成本'
      },
      block5: {
        text1: '易于管理和维护',
        text2: '网吧管理者可以更容易地管理软件和游戏，因为所有的内容都在云端集中管理。这减少了本地维护工作和潜在的技术问题'
      }
    },
    cont3: {
      text1: '韩国Glowsteam云网吧',
      text2: '云网吧实际运行环境'
    },
    cont4: {
      text1: '云网吧方案4年时间可以帮助网吧投资者相对传统网吧方案',
      text2: '提升3倍的净利润，回本周期缩短40%',
      text3: '点击下载对比数据'
    },
    cont5: {
      text1: '云网吧解决方案商的收益如何？',
      text2: '如果按照每个网吧机器的收益12%作为收益，则服务1000个网吧总共100000台机器，每个机器平均每天使用10个小时，每个网吧机器平均费用是0.8美金，则12%约等于0.1美金',
      text3: '云网吧解决方案商一年的收益总和为',
      text4: '100000*10*0.1*365=3650万美金',
      text5: '如何成为云网吧解决方案商',
      text6: '韩国HYCONS基于DeepBrainChain和DeepLink技术开发了一套完整的云网吧解决方案，其中DeepBrainChain提供GPU算力，DeepLink提供超低延迟渲染技术，HYCONS提供完整解决方案（如果类比一辆车，DeepBrainChain提供发动机，DeepLink提供无人驾驶系统，HYCONS提供完整的车解决方案，品牌商只需要换个车的颜色贴一个自己的品牌就可以售卖），各个国家本地方案商只需要在HYCONS基础上接入本地支付系统就可以给本地网吧提供服务，并且方案商可以设置自己的品牌，HYCONS只作为技术服务商不暴露品牌',
      text7: '具体可以联系 ：',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: '云网吧相关资料下载',
      text1: 'HYCONS介绍',
      text2: '云网吧方案收益分析',
      text3: '云网吧数据中心架构图',
      text4: '云网吧方案测试安装手册',
      text5: 'DeepLInk协议介绍',
    }
  },
  reward2: {
    desc: {
      title1: 'DeepLink Nodes',
      text1: '只有Node拥有者才能参与CPU流量挖矿和个人GPU机器挖矿， 奖励总数是12%的DLC Token，4％是CPU流量节点， 8％是个人GPU节点.代币数量为120亿.每个节点产出数量 与实际卖出的节点有关系，如果全部卖掉，每个节点 产出10万DLC，如果只卖出10％的节点，则每个节点产出100万个DLC',
      title2: 'Node总数：120,000 永不增发',
      text2: '每Node额外空投',
      text3: '每Node挖出来的DLC数量最少',
      text4: '初始价格',
      text5: '最高价格'
    },
    btn_text: '购买节点',
    table: {
      tier: 'Tier',
      license: '节点许可证价格 USDT',
      total_num: '许可证总数',
      total_usdt: 'USDT 总金额',
      buy: '购买'
    },
    intro: {
      title: '节点挖矿介绍',
      text1_1: 'DeepLink的网络用户越多，需要越多的网络流量和计算，从而需要更多的CPU流量机器和更多的GPU计算机器为DeepLink网络提供 基础设施，从而实现去中心化和帮助DeepLink更快速的发展。通过购买节点授权，您就可以参与挖矿获得奖励的机会。 有什么疑问吗？ 来',
      text1_2: '和',
      text1_3: '上面与我们一起交流。',
      text2: '每个节点以 NFT 形式发行，并额外获得 BSC 链 30,000 DLC 空投。空投在交易所上市前开始，空投 DLC 的 20% 立即解锁，80% 在 8 个月内解锁。\n（查看 DLC Token 流程：打开钱包选择 BSC 网络，选择添加 Token 并输入合约地址：0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC）',
      text3: '节点2024年10月31日之前不能转售给其他人。',
      text4: '挖出来的Token 25％立即解锁，75％分150天线性解锁。',
      text5: '如果Listing后10天内挖矿没开始，采用空投的形式，每7天空投一次。空投规则与挖矿产出规则一样，每天的产出总数是410万 DLC.根据节点售卖的总数平均分配。25％立即解锁，75％150天线性解锁。如果挖矿已经开始则需要对应的机器才能获得奖励。',
      text6: '个人购买1000个以上节点的投资者，将获得运营节点的全套支持，以及对接合作运营商协助运行节点的权利。',
      text7: '一台虚拟机可以支持20个NFT节点，收益翻20倍。',
      text8: '购买DeepLink 节点，参与900万美金的Orion GPU算力竞赛: '
    },
    question: {
      ques1: '总共售卖多少个节点NFT？',
      ans1: '总共12万个',
      ques2: '每个节点NFT需要支付多少费用？',
      ans2: '分为10级，价格从30U到155U不等，在官网售卖，售完为止',
      ques3: '购买节点NFT是一次性的？',
      ans3: '购买节点是一次性的,以后不需要额外付费',
      ques4: '购买节点NFT有额外的空投吗？',
      ans4: '有的，每个节点拥有者空投30000DLC,Listing交易所之前开始空投，空投的DLC 20%不锁定，80%分8个月解锁',
      ques5: '具体的挖矿机制是什么？',
      ans5: '总共奖励是120亿DLC,流量节点总收益是40亿DLC，个人GPU机器节点总收益是80亿DLC，购买一个节点NFT，可以同时参与这两个挖矿，前4年每年奖励是15亿，每4年减半一次。每天的奖励总数是411万DLC，只奖励给节点NFT所有者。挖出来的Token 25％立即解锁，75％分150天线性解锁',
      ques6: '购买的一个节点NFT可以同时参与CPU流量机器节和GPU个人机器节点挖矿吗?',
      ans6: '可以',
      ques7: '什么是CPU带宽流量机器节点？',
      ans7: 'CPU带宽流量机器节点承担DeepLink网络计算和流量转发的核心任务，DeepLink通过挖矿的模式，让更多人可以参与获得收益。流量节点总收益是40亿DLC',
      ques8: '第一年带宽流量机器总共的奖励是多少？',
      ans8: '第一年奖励是5亿DLC，每天奖励大约137万个DLC',
      ques9: '每个CPU带宽流量机器节点的收益和带宽的关系？',
      ans9: '基础带宽是10Mb上行带宽。10Mb上行带宽带宽值为10，带宽只能按照10的倍数有效，比如45Mb上行带宽，按照Mb计算，带宽值为40。带宽被实际使用带宽值增加30％。最后按照实际带宽值平分受益',
      ques10: '带宽流量机器节点的设备的配置要求高吗？有配置参数信息吗？具体怎么参与挖矿？',
      ans10: '配置要求不高.vCPU 1C + 内存 2G + 50G 硬盘。在aws租用一个月10 USD，带宽根据实际使用计费。在AWS租用一个CPU虚拟机过后，安装DeepLink流量节点挖矿程序，创建钱包地址，将节点NFT转入到钱包中，启动挖矿功能就可以参与挖矿',
      ques11: '什么是个人GPU机器节点？',
      ans11: '个人可以提供自己GPU电脑参与挖矿，GPU机器给DeepLink Protocol提供云游戏基础设施服务，个人GPU机器节点总收益是80亿DLC',
      ques12: '第一年个人GPU机器节点总共的奖励是多少？',
      ans12: '第一年奖励是10亿DLC，每天奖励大约274万个DLC',
      ques13: '个人GPU机器节点机器配置如何？具体怎么参与挖矿？',
      ans13: 'GPU机器配置要求：CPU频率不低于3.0GHZ, GPU为Nvidia Geforce系列：20xx、30xx、40xx, 内存不低于16G'
    }
  },
  BandWidth: {
    title: '流量带宽挖矿规则',
    text1: '流量带宽挖矿总共有40亿DLC的奖励，前四年每年5亿的DLC奖励，每4年减半一次',
    cont2: {
      title: '一.参与规则',
      text1_1: '需要持有节点NFT（每个节点送3万个DLC空投,价值90美金,详细了解节点：',
      text1_2: '）。一个CPU机器可以持有多个节点，奖励Token数量和节点数量是乘数关系。单个机器持有节点NFT上限是20个。',
      text2_1: 'CPU服务器参数最低要求： \n 算力服务器各项参数需要满足最低要求',
      text3_1: 'CPU',
      text3_2: 'vCPU 1C',
      text4_1: '内存',
      text4_2: '2G',
      text5_1: '硬盘：',
      text5_2: '50G 硬盘',
      text6_1: '网络带宽：',
      text6_2: '基础带宽是10Mb上行带宽；建议每带宽增加200M，CPU 增加1C , 内存增加2G',
    },
    cont3: {
      title: '二.流量挖矿区域规则',
      text: '流量挖矿会对全球分为130个左右的区域，每个区域分配不同的奖励DLC总数，区域奖励计算公式，区域奖励=区域值*每日奖励/总区域值',
      text1: '区域列表如下：',
      text2: '亚洲',
      text3: '美洲',
      text4: '欧洲',
      text5: '非洲',
      text6: '大洋洲',
      li_other: '其他',
      li1: '中国',
      li1_1: '华北地区',
      li1_2: '东北地区',
      li1_3: '华东地区',
      li1_4: '华中地区',
      li1_5: '华南地区',
      li1_6: '西南地区',
      li1_7: '西北地区',
      li1_8: '中国台湾',
      li1_9: '中国香港',

      li2: '印度',
      li2_1: 'Uttar Pradesh',
      li2_2: 'Maharashtra',
      li2_3: 'Bihar',
      
      li2_4: '印度尼西亚',
      li2_5: '巴基斯坦',
      li2_6: '孟加拉国',
      li2_7: '日本',
      li2_8: '菲律宾',
      li2_9: '越南',
      li2_10: '土耳其',
      li2_11: '泰国',
      li2_12: '韩国',
      li2_13: '马来西亚',
      li2_14: '沙特阿拉伯',
      li2_15: '阿联酋',

      li3: '美国',
      li3_1: '加利福尼亚州',
      li3_2: '德克萨斯州',
      li3_3: '佛罗里达州',
      li3_4: '纽约州',
      li3_5: '宾夕法尼亚州',
      li3_6: '伊利诺伊州',
      li3_7: '俄亥俄州',
      li3_8: '佐治亚州',
      li3_9: '密歇根州',
      li3_10: '北卡罗来纳州',
      li3_11: '美国其他区域',

      li4_1: '墨西哥',
      li4_2: '加拿大',
      li4_3: '巴西',
      li4_4: '哥伦比亚',
      li4_5: '阿根廷',

      li5: '俄罗斯',
      li5_1: '莫斯科市',
      li5_2: '圣彼得堡市',
      li5_3: '俄罗斯其他地方',
      li5_4: '德国',
      li5_5: '英国',
      li5_6: '法国',
      li5_7: '意大利',
      li5_8: '西班牙',
      li5_9: '荷兰',
      li5_10: '瑞士',
      
      li6_1: '尼日利亚',
      li6_2: '埃及',
      li6_3: '南非',

      li7_1: '澳大利亚',
    },
    cont5: {
      title: '四.奖励机制 （基于DBC AI公链的EVM兼容的DLC，浏览器地址：https://test.dbcscan.io/）',
      text1: '前四年每年的挖矿奖励是5亿DLC，每天是136.9万DLC.每个区域根据权重比例分配DLC奖励数量。如果某个区域没有机器，则此区域的DLC奖励会被自动销毁，不分配给任何人',
      text2: '这个系统的奖励每个区域每天的总token数量是固定的，机器获得奖励与机器的带宽以及机器加入挖矿的有效时间Y,以及机器的质押Token数量有关系，质押的Token越多奖励越多，但是呈现非线性增长关系。0-10000个币奖励基数是一样的，当超过10000个币奖励与函数呈现关联',
      text3: '如果机器流量带宽处于被使用状态，算力值增加30%,节点NFT的数量与算力值是乘数关系，当前每个机器最多持有节点NFT的数量是20个',
      text4: '只有拥有节点NFT的CPU机器才能够被计入在线奖励',
      text5: '当前1个机器最多可以绑定20个节点NFT，NFT数量越多奖励越多，呈现线性关系',
      text6: '前300天单个机器最多绑定20个节点NFT',
      text7: '前600天单个机器最多绑定10个节点NFT',
      text8: '600到900天每个机器最多绑定5个节点NFT',
      text9: '900到1200天每个机器最多绑定2个节点NFT',
      text10: '1200到1800天每个机器最多绑定1个节点NFT',
      text11: '1800天以后每个节点NFT可以绑定2个机器',
      text12: '24000天以后每个节点NFT可以绑定4个机器',
      text13: '计算奖励：',
      text14: 'R：每天的总奖励Token数量（固定值）',
      text15: '第i台机器的质押Token数量',
      text16: '第i台机器的算力值',
      text17: '第i台机器的有效挖矿时间',

      text18: '其中N 是参与挖矿的机器总数',
      text19: '100000个币的奖励是质押10000个币的1.23026倍，即大约增加了23.03%',
      text20: '100万个币的奖励是质押10000个币的1.46052倍，即大约增加了46.05%',
      text21: '质押1000万个币的奖励是质押10000个币的1.69078倍，即大约增加了69.08%',
      text22: '质押1亿个币的奖励是质押10000个币的1.92103倍，即大约增加了92.10%',

      title1: '奖励提取：',
      text1_1: '每24小时可以领取一次奖励',
      text1_2: '如果刚开始没有质押DLC或质押数量不足10000，则奖励收益会沉淀为质押的DLC,一直到1万个DLC',
      text1_3: '1）租金会沉淀为质押金，一直到10000个DLC',
      text1_4: '2）最短必须出租2个小时，最长可以出租时间无限制，当剩余可用时长剩余少于1小时机器将不能出租，且不计入在线奖励，矿工可以随时增加时长，但是每次增加时长最少增加2小时',

      title2: '机器每天DLC收益计算公式：DLC按照0.003U的价格计算，',
      text2_1: '我的CPU机器带宽数量：',
      text2_2: '节点NFT数量：',
      text2_3: '地域：',
      text2_4: '质押DLC数量：',
      text2_5: '带宽总数：',
      text2_6: '(NFT 平均10个, 平均质押1万个DLC 计算)',
      text2_7: '请选择',
      title3: '链上奖励收益: {dlc_num} DLC(等值 {usdt_num} USD 当前DLC价格: 0.003 USD)',
      title4: '五.举报以及验证机制 (基于DBC AI公链的EVM兼容的 DLC)',
      text4_1: '机器常驻有DBC AI公链的检测机制，上报信息给DBC分布式检测集群，用来判断机器是否处于在线状态，当不处于在线状态则扣除在线奖励。如果在用户使用过程中出现中断则扣除DLC给用户。惩罚的数量限制：',
      text4_2: '1）如果没质押任何币，则只惩罚沉淀的奖励，最多10000个DLC',
      text4_3: '2）如果质押了DLC, 则最多惩罚10000个DLC',
    }
  },
  coverage: {
    title: 'DeepLink软件全球用户分布图',
    text: 'DeepLink用户总数：'
  },
}