// 土耳其语
export default {
  meau: {
    nav: 'Ana Sayfa',
    "nav1": "Madencilik Düğümü Satın Al",
    "nav2": "Orion GPU Yarışı",
    "nav3": "",
    nav3: 'DeepLink Yazılımı',
    nav3_1: 'Yazılım İndir',
    nav3_2: 'Yazılım Lisansı',
    nav4: 'Bant Genişliği Madenciliği',
    "nav5": "Bulut İnternet Kafe",
    "nav6": "Blog",
    nav7: 'Docs'
  },
  reward2: {
    desc: {
      title1: 'DeepLink Düğümleri',
      text1: 'Yalnızca Node sahipleri CPU trafik madenciliği ve kişisel GPU makine madenciliğine katılabilir. Toplam ödül, %4’ü CPU trafik node’ları ve %8’i kişisel GPU node’ları için olmak üzere %12 DLC Token’dır. Toplam token miktarı 12 milyar. Her node’un üretimi, satılan node’ların sayısına bağlıdır. Eğer hepsi satılırsa, her node 100,000 DLC üretir. Eğer yalnızca %10’u satılırsa, her node 1 milyon DLC üretir.',
      title2: 'Toplam Node sayısı: 120,000 ek ihraç yok',
      text2: 'Her Node için ek airdrop',
      text3: 'Her Node tarafından çıkarılan minimum DLC miktarı',
      text4: 'Başlangıç fiyatı',
      text5: 'Maksimum fiyat'
    },
    btn_text: 'Düğümleri Satın Al',
    table: {
      tier: 'Katman',
      license: 'Node Lisans Fiyatı (USDT)',
      total_num: 'Toplam Lisans Sayısı',
      total_usdt: 'Toplam USDT',
      buy: 'Satın Al'
    },
    intro: {
      title: 'Node Madenciliği Tanıtımı',
      text1_1: "DeepLink ağının kullanıcı sayısı arttıkça, daha fazla ağ trafiği ve hesaplama gücü gerekecek, bu da DeepLink ağına altyapı sağlamak için daha fazla CPU trafiği makinesi ve daha fazla GPU hesaplama makinesi gerektirecek. Bu, merkeziyetsizliği sağlar ve DeepLink'in daha hızlı gelişmesine yardımcı olur. Bir Node lisansı satın alarak madenciliğe katılma ve ödül kazanma fırsatına sahip olursunuz. Sorularınız mı var? Gelin",
      text1_2: 've',
      text1_3: 'bizimle yukarıda sohbet edin.',
      text2: "Her düğüm bir NFT biçiminde verilir ve ayrıca BSC zinciriyle 30.000 DLC'lik bir airdrop alır. Airdrop, borsada listelenmeden önce başlar ve airdrop DLC'sinin %20'si hemen, %80'i ise 8 ay içinde açılır. \n ( DLC Token İşlemini Görüntüle: Cüzdanı açın ve BSC ağını seçin, Token Ekle'yi seçin ve sözleşme adresini girin: 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC )",
      text3: 'Nodlar 31 Ekim 2024 tarihinden önce başkalarına satılamaz.',
      text4: "Kazılan tokenlerin %25'i hemen kilidi açılır, %75'i 150 gün boyunca lineer olarak kilidi açılır.",
      text5: "Listeleme sonrası 10 gün içinde madencilik başlamazsa, ödüller her 7 günde bir yapılan airdroplar şeklinde dağıtılır. Airdrop kuralları, madencilik üretim kuralları ile aynıdır ve günlük toplam üretim 4,1 milyon DLC'dir, satılan node sayısına eşit olarak dağıtılır. %25 hemen kilidi açılır, %75 150 gün boyunca lineer olarak kilidi açılır. Madencilik zaten başladıysa, ödül almak için ilgili makineler gereklidir.",
      text6: "20'den fazla düğüm satın alımlarında, düğüm operasyonları devredilebilir. 1.000'den fazla düğümü tek tek satın alan yatırımcılara, düğümleri çalıştırmak için tam bir destek seti sağlanacak ve ayrıca düğümleri çalıştırmaya yardımcı olmak için bir kooperatif operatörüyle bağlantı kurma olanağı sağlanacaktır.",
      text7: "Bir sanal makine 20 adet NFT node'unu destekleyebiliyor ve gelir 20 katına çıkıyor.",
      text8: 'DeepLink düğümünü satın alın ve 9 milyon dolarlık Orion GPU hesaplama gücü yarışmasına katılın: '
    },
    question: {
      ques1: 'Toplamda kaç Node NFT satılıyor?',
      ans1: 'Toplamda 120,000',
      ques2: 'Her Node NFT’nin maliyeti nedir?',
      ans2: '10 seviyeye bölünmüştür, fiyatlar 30U ile 155U arasında değişmekte olup, resmi web sitesinde satılmaktadır, satılana kadar',
      ques3: 'Node NFT satın almak tek seferlik bir ödeme midir?',
      ans3: 'Evet, Node satın almak tek seferlik bir ödemedir ve gelecekte ek ücret gerekmez',
      ques4: 'Node NFT satın alırken ek airdrop var mı?',
      ans4: 'Evet, her Node sahibi 30,000 DLC airdrop alır. Airdrop, borsada listelemeden önce başlar ve airdrop DLC’nin %20’si hemen kilitsiz hale gelir ve %80’i 8 ay boyunca kilitlenir',
      ques5: 'Belirli madencilik mekanizması nedir?',
      ans5: 'Toplam ödül 12 milyar DLC’dir. Trafik nodelarının toplam kazancı 4 milyar DLC, kişisel GPU makinelerinin nodelarının toplam kazancı ise 8 milyar DLC’dir. Bir Node NFT satın alarak her iki madencilik türüne de aynı anda katılabilirsiniz. İlk 4 yıl için ödül yılda 1.5 milyar DLC’dir, her 4 yılda bir yarıya iner. Günlük toplam ödül 4.11 milyon DLC’dir ve sadece Node NFT sahiplerine dağıtılır. Kazılan tokenların %25’i hemen kilitsiz hale gelir, %75’i ise 150 gün boyunca lineer olarak kilitsiz hale gelir',
      ques6: 'Satın alınan bir Node NFT, CPU trafik makinesi madenciliğine ve kişisel GPU makinesi madenciliğine aynı anda katılabilir mi?',
      ans6: 'Evet',
      ques7: 'CPU bant genişliği trafik makinesi nodu nedir?',
      ans7: 'CPU bant genişliği trafik makinesi nodu, DeepLink ağında hesaplama ve trafik yönlendirme görevlerini üstlenir. Madencilik modeli aracılığıyla DeepLink, daha fazla kişinin katılmasını ve ödül kazanmasını sağlar. Trafik nodelarının toplam kazancı 4 milyar DLC’dir',
      ques8: 'İlk yıl için bant genişliği trafik makineleri için toplam ödül nedir?',
      ans8: 'İlk yıl için ödül 500 milyon DLC’dir, günlük yaklaşık 1.37 milyon DLC ödüllendirilir',
      ques9: 'Her CPU bant genişliği trafik makinesi nodunun bant genişliği ile kazancı arasındaki ilişki nedir?',
      ans9: 'Temel bant genişliği, 10Mb uplink bant genişliğidir. Bant genişliği değeri, 10Mb uplink bant genişliği için 10’dur. Bant genişliği sadece 10’un katları halinde geçerlidir, örneğin, 45Mb uplink bant genişliği bant genişliği değeri olarak 40 olarak hesaplanır. Bant genişliği, gerçek kullanımına göre %30 artar. Son olarak, kazançlar gerçek bant genişliği değerine göre paylaşılır',
      ques10: 'Bant genişliği trafik makineleri nodları için konfigürasyon gereksinimleri yüksek mi? Konfigürasyon bilgileri var mı? Madenciliğe nasıl katılabilirsiniz?',
      ans10: 'Konfigürasyon gereksinimleri yüksek değil. vCPU 1C + 2G bellek + 50G sabit disk. AWS’de kiralama aylık yaklaşık 10 USD’dir, bant genişliği gerçek kullanımına göre ücretlendirilir. AWS’de bir CPU sanal makinesi kiraladıktan sonra, DeepLink trafik nodu madencilik programını yükleyin, bir cüzdan adresi oluşturun, Node NFT’yi cüzdana aktarın ve madencilik fonksiyonunu etkinleştirerek madenciliğe katılın',
      ques11: 'Kişisel GPU makinesi nodu nedir?',
      ans11: 'Bireyler, madenciliğe katılmak için kendi GPU bilgisayarlarını sunabilirler. GPU makineleri, DeepLink Protokolü için bulut oyun altyapı hizmetleri sağlar. Kişisel GPU makineleri nodelarının toplam kazancı 8 milyar DLC’dir',
      ques12: 'İlk yıl için kişisel GPU makineleri nodeları için toplam ödül nedir?',
      ans12: 'İlk yıl için ödül 1 milyar DLC’dir, günlük yaklaşık 2.74 milyon DLC ödüllendirilir',
      ques13: 'Kişisel GPU makineleri nodeları için konfigürasyon gereksinimleri nelerdir? Madenciliğe nasıl katılabilirsiniz?',
      ans13: 'GPU makineleri için konfigürasyon gereksinimleri: en az 3.0GHz CPU frekansı, Nvidia Geforce serisi GPU: 20xx, 30xx, 40xx ve en az 16G bellek'
    }
  },
  BandWidth: {
    "title": "Trafik Bant Genişliği Madenciliği Kuralları",
    "text1": "Trafik bant genişliği madenciliğinde toplam 4 milyar DLC ödül bulunmakta, ilk dört yıl için yılda 500 milyon DLC ödülü verilmekte, her dört yılda bir yarıya indirilmektedir.",
    "cont2": {
      "title": "Katılım Kuralları",
      "text1_1": "Bir Node NFT sahibi olunması gerekmektedir (her node, 30,000 DLC hava atışı sağlar, değeri 90 dolar, node hakkında daha fazla bilgi: ",
      "text1_2": "). Tek bir CPU makinesi birden fazla node tutabilir, ödül token miktarı node sayısına orantılıdır. Tek bir makine maksimum 20 Node NFT tutabilir.",
      "text2_1": "CPU sunucuları için minimum gereksinimler: \n Hesaplama sunucusunun parametreleri minimum gereksinimleri karşılamalıdır",
      "text3_1": "CPU",
      "text3_2": "vCPU 1C",
      "text4_1": "Bellek",
      "text4_2": "2G",
      "text5_1": "Sabit Disk:",
      "text5_2": "50G sabit disk",
      "text6_1": "Ağ Bant Genişliği:",
      "text6_2": "Temel bant genişliği 10Mb yükleme bant genişliğidir; her 200M bant genişliği artışı için 1C CPU ve 2G bellek eklenmesi önerilir"
    },
    cont3: {
      title: 'Trafik Madenciliği Bölgesel Kurallar',
      text: 'Trafik madenciliği, dünyayı yaklaşık 130 bölgeye ayıracak, her bölgeye farklı DLC ödül toplamları tahsis edilecektir. Bölgesel ödül formülü şöyledir: Bölgesel ödüller = bölgesel değer * günlük ödül / toplam bölgesel değer',
      text1: 'Bölgelerin listesi aşağıdaki gibidir:',
      text2: 'Asya',
      text3: 'Amerika',
      text4: 'Avrupa',
      text5: 'Afrika',
      text6: 'Okyanusya',
      li_other: 'Diğer',
      li1: 'Çin',
      li1_1: 'Kuzey Çin',
      li1_2: 'Kuzeydoğu Çin',
      li1_3: 'Doğu Çin',
      li1_4: 'Merkezi Çin',
      li1_5: 'Güney Çin',
      li1_6: 'Güneybatı Çin',
      li1_7: 'Kuzeybatı Çin',
      li1_8: 'Tayvan, Çin',
      li1_9: 'Hong Kong, Çin',
      li2: 'Hindistan',
      li2_1: 'Uttar Pradeş',
      li2_2: 'Maharashtra',
      li2_3: 'Bihar',
      li2_4: 'Endonezya',
      li2_5: 'Pakistan',
      li2_6: 'Bangladeş',
      li2_7: 'Japonya',
      li2_8: 'Filipinler',
      li2_9: 'Vietnam',
      li2_10: 'Türkiye',
      li2_11: 'Tayland',
      li2_12: 'Güney Kore',
      li2_13: 'Malezya',
      li2_14: 'Suudi Arabistan',
      li2_15: 'Birleşik Arap Emirlikleri',

      li3: 'Amerika Birleşik Devletleri',
      li3_1: 'Kaliforniya',
      li3_2: 'Teksas',
      li3_3: 'Florida',
      li3_4: 'New York',
      li3_5: 'Pensilvanya',
      li3_6: 'Illinois',
      li3_7: 'Ohio',
      li3_8: 'Georgia',
      li3_9: 'Michigan',
      li3_10: 'Kuzey Karolina',
      li3_11: 'ABD’nin Diğer Bölgeleri',
      
      li4_1: 'Meksika',
      li4_2: 'Kanada',
      li4_3: 'Brezilya',
      li4_4: 'Kolombiya',
      li4_5: 'Arjantin',
      
      li5: 'Rusya',
      li5_1: 'Moskova',
      li5_2: 'Saint Petersburg',
      li5_3: "Rusya'nın Diğer Bölgeleri",
      li5_4: 'Almanya',
      li5_5: 'Birleşik Krallık',
      li5_6: 'Fransa',
      li5_7: 'İtalya',
      li5_8: 'İspanya',
      li5_9: 'Hollanda',
      li5_10: 'İsviçre',
      
      li6_1: 'Nijerya',
      li6_2: 'Mısır',
      li6_3: 'Güney Afrika',
      
      li7_1: 'Avustralya',
    },
    cont5: {
      title: "Ödül Mekanizması (DBC AI genel zincirinin EVM uyumlu DLC'sine dayalı, Tarayıcı URL'si: https://test.dbcscan.io/)",
      text1: "İlk dört yıl için madencilik ödülü yıllık 500 milyon DLC, günlük olarak 1.369 milyon DLC'dir. Her bölgeye ağırlık oranlarına göre DLC ödülleri tahsis edilir. Bir bölgede makine yoksa, o bölgenin DLC ödülleri otomatik olarak yok edilir ve kimseye tahsis edilmez.",
      text2: "Bu sistemde her bölge için günlük toplam token miktarı sabittir. Makinenin aldığı ödül, makinenin bant genişliği, etkin madencilik süresi Y ve yatırılan token sayısına bağlıdır. Yatırılan token sayısı arttıkça ödül de artar, ancak bu artış doğrusal olmayan bir ilişkidir. 0-10.000 coin için ödül tabanı aynıdır; 10.000 coin'i aştığında ödüller bir fonksiyonla ilişkilendirilir.",
      text3: "Makinenin trafik bant genişliği kullanımda ise, hesaplama gücü değeri %30 artar ve düğüm NFT'lerinin sayısı hesaplama gücü değeri ile çarpan ilişkisindedir. Şu anda her makine en fazla 20 düğüm NFT'sine sahip olabilir.",
      text4: "Yalnızca düğüm NFT'lerine sahip CPU makineleri çevrimiçi ödüller için uygun kabul edilir.",
      text5: "Şu anda bir makine en fazla 20 düğüm NFT'sine bağlanabilir, NFT sayısı arttıkça ödül de artar, bu doğrusal bir ilişki gösterir.",
      text6: "İlk 300 gün içinde bir makine en fazla 20 düğüm NFT'sine bağlanabilir.",
      text7: "İlk 600 gün içinde bir makine en fazla 10 düğüm NFT'sine bağlanabilir.",
      text8: "600 ile 900 gün arasında her makine en fazla 5 düğüm NFT'sine bağlanabilir.",
      text9: "900 ile 1200 gün arasında her makine en fazla 2 düğüm NFT'sine bağlanabilir.",
      text10: "1200 ile 1800 gün arasında her makine en fazla 1 düğüm NFT'sine bağlanabilir.",
      text11: "1800 gün sonrasında her düğüm NFT'si 2 makineye bağlanabilir.",
      text12: "24,000 gün sonrasında her düğüm NFT'si 4 makineye bağlanabilir.",
      text13: 'Ödülleri hesaplama:',
      text14: 'R: Günlük toplam ödül Token miktarı (sabit değer)',
      text15: 'i. makinenin yatırılan Token miktarı',
      text16: 'i. makinenin hesaplama gücü değeri',
      text17: 'i. makinenin etkin madencilik süresi',

      text18: 'Burada N, madenciliğe katılan makinelerin toplam sayısıdır',
      text19: '100.000 coin için ödül, 10.000 coin yatırıldığında 1.23026 katıdır, yaklaşık olarak %23.03 artış gösterir',
      text20: '1 milyon coin için ödül, 10.000 coin yatırıldığında 1.46052 katıdır, yaklaşık olarak %46.05 artış gösterir',
      text21: '10 milyon coin yatırıldığında ödül, 10.000 coin yatırıldığında 1.69078 katıdır, yaklaşık olarak %69.08 artış gösterir',
      text22: '100 milyon coin yatırıldığında ödül, 10.000 coin yatırıldığında 1.92103 katıdır, yaklaşık olarak %92.10 artış gösterir',
      
      title1: 'Ödül Talebi:',
      text1_1: 'Ödüller her 24 saatte bir kez talep edilebilir',
      text1_2: "Başlangıçta DLC yatırılmamışsa veya yatırılan miktar 10.000'den azsa, kazançlar 10.000 DLC'ye ulaşana kadar yatırılmış DLC olarak birikir",
      text1_3: "1) Kiralama geliri, 10.000 DLC'ye ulaşana kadar yatırılmış sermaye olarak birikir",
      text1_4: '2) Minimum kiralama süresi 2 saattir, maksimum süre sınırlaması yoktur; kullanılabilir süre 1 saatten az kaldığında makine kiralanamaz ve çevrimiçi ödüllerde sayılmaz. Madenciler istedikleri zaman süreyi uzatabilir, ancak her uzatma en az 2 saat olmalıdır',
      
      title2: 'Makineler için Günlük DLC Kazanç Hesaplama Formülü: DLC, 0.003U fiyatından hesaplanır,',
      text2_1: 'CPU makinemin bant genişliği:',
      text2_2: 'Düğüm NFT sayısı:',
      text2_3: 'Bölge:',
      text2_4: 'Yatırılan DLC miktarı:',
      text2_5: 'Toplam bant genişliği:',
      text2_6: '(Ortalama 10 NFT ve 10.000 DLC yatırma varsayılarak hesaplanmıştır)',
      text2_7: 'Lütfen seçin',
      title3: 'Zincir üstü ödül geliri: {dlc_num} DLC (mevcut DLC fiyatı: 0.003 USD karşılığında {usdt_num} USD)',
      title4: "İhbar ve Doğrulama Mekanizması (DBC AI Kamu Zincirinin EVM uyumlu DLC'sine dayanmaktadır)",
      text4_1: 'Makine sürekli olarak DBC AI kamu zincirinin tespit mekanizmasına sahiptir, bilgileri DBC dağıtık tespit kümesine rapor eder ve makinenin çevrimiçi olup olmadığını belirler; çevrimiçi değilse çevrimiçi ödüller kesilir. Kullanıcı kullanımı sırasında kesinti olursa, kullanıcıdan DLC kesilir. Ceza sınırı:',
      text4_2: '1) Herhangi bir coin yatırılmamışsa, sadece birikmiş ödüller cezalandırılır, en fazla 10.000 DLC',
      text4_3: '2) DLC yatırılmışsa, en fazla 10.000 DLC cezalandırılabilir',
    }
  },
  
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: 'Dağıtık AI Bulut Oyun Protokolü',
      title2: 'DeepLink Protocol, bulut oyun geliştirmeyi daha önce hiç olmadığı kadar kolay hale getiriyor',
      btn1: 'PinkSale\'e katıl',
      btn2: 'Whitepaper\'ı indir',
      btn3: 'Pitch Deck\'i indir',
      btn4: 'DeepLink Kamu Satışı',
      btn5: 'Node Ödüllerine Hoşgeldiniz',
      btn6: 'Client\'ı indir',
      desc1: 'DLC Token almak için DeepLink desteği',
      desc2: '%100 Güvenli ve Doğrulanmış',
      desc3: 'NVIDIA Inception Programı'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: 'Gösterilen verimlilik, 50 km uzaklıkta iki bilgisayar örneğiyle açıklanmaktadır. Kontrol cihazı sıradan bir dizüstü bilgisayar, kontrol edilen cihaz ise yüksek kaliteli bir GPU makinesidir. Ekranda oyun dinamik görüntüsü gösterilmektedir, dinamik şekli yaklaşık 3-5 saniye sürmektedir.',
      btn: 'Daha fazla bilgi için videoyu izleyin'
    },
    cont3: {
      text1: 'Süper düşük gecikme',
      text2: 'Yüksek çözünürlük',
      text3: 'Süper düşük gecikme',
      text4: 'Kesilme ve takılma yok, yüksek stabilite',
      desc1: 'DeepLink Protocol, devrim niteliğinde teknolojik atılımlar gerçekleştirerek benzersiz bir deneyim sunuyor.',
      desc2: 'Temel teknoloji ekibi üyeleri 2011 yılından itibaren bulut oyun sektöründe yer alıyor ve Cisco, Intel, Nvidia, Huawei, ShunNet gibi şirketlerden gelen üyeler bulunuyor. 10 yıldan fazla Ar-Ge birikimiyle DeepLink doğdu.'
    },
    cont16: {
      title1_1: 'DeepLink Protocol, bulut oyunları için ultra-düşük gecikme teknolojisi hizmeti sunar',
      title1_2: 'Bulut oyunları için ultra-düşük gecikme teknolojisi hizmeti sunar',
      title2: 'Şu anda, DeepLink Protocol tabanlı geliştirilen ürünler aşağıdaki gibi olup, 20\'den fazla uygulama geliştirilme aşamasındadır',
      text1: 'Uzak kontrol',
      text2: 'Bulut oyun & Bulut oyun platformları',
      text3: 'Bulut internet kafesi',
      text4: 'Bulut XR',
      text5: 'XXX Bulut VR oyunları',
      text6: 'YYY Bulut oyun platformu'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: 'Uzak kontrol aracını trafik girişi olarak kullanmak',
      title2: 'DeepLink yazılımı, uzak kontrol aracını trafik girişi olarak kullanır ve bulut oyun platformları ile bulut internet kafeleri gelir elde etme aracı olarak kullanır',
      text1_1: '900,000+',
      text1_2: 'Aylık aktif kullanıcı sayısı',
      text2_1: '110+',
      text2_2: 'Hizmet sunulan ülke sayısı',
      text3_1: '2,500,000+',
      text3_2: 'Toplam kullanıcı sayısı',
      text4_1: '300,000+',
      text4_2: 'Günlük aktif kullanıcı sayısı',
      desc1: 'Uzak kontrol aracı',
      desc2: 'Bulut oyunları ve Cloud XR',
      desc3: 'Bulut internet kafesi',
      btn1: 'YouTube KOL Seviye 1',
      btn2: 'YouTube KOL Seviye 2',
      btn3: 'YouTube KOL Seviye 3',
      btn4: 'YouTube KOL Seviye 4'
    },
    cont17: {
      title1: 'DeepLink Protocol İş Modeli',
      text1: 'Komisyon Modeli',
      text2: 'Lisans Modeli',
      desc1: 'Her bulut oyunu işlemi için DeepLink Protocol, %20-30 komisyon alır, komisyon DLC ile ödenir ve %100 DLC anında imha edilir.',
      desc2: 'Bulut internet kafeleri ve bulut e-spor otelleri gibi uygulamalar için, her uygulama her bilgisayar terminali için aylık 6 dolar lisans ücreti öder.'
    },
    cont5: {
      title1: 'Blockchain teknolojisi ile madencilerin kullanılmayan ağ trafiği sağlaması',
      desc1: 'Node Bağlantısı',
      desc2_1: 'Gerekli cihazlar',
      desc2_2: 'Trafik iletimi',
      desc3: 'Dünyadaki madenciler NFT satın alarak trafik düğümleri sağlıyor ve DLC token ödülleri alıyor',
      desc3_2: '',
      title2: 'Trafik Düğümü Sağlayıcılar',
      text2: 'Madenciler trafik düğümleri sağlar', 
      title3: 'Kullanıcı Tarafı',
      text3: 'Bireysel kullanıcılar'
    },
    cont6: {
      title1: 'DeepLink’in piyasa rekabet gücünü büyük ölçüde artırma',
      title2: 'Blockchain teknolojisi ile',
      desc1: 'Akıllı sözleşmeler aracılığıyla dijital para stake edilerek GPU sağlayıcılarının istikrarlı hizmet vermesi garanti altına alınır',
      desc2: 'Herkes ağda katkıda bulunabilir ve ödüller alabilir, örneğin GPU sunucusu sağlama veya trafik düğümü sağlama',
      desc3: 'Madencileri trafik iletim düğümü sağlayıcıları olarak dahil etmek, düğümlerin yaygın olarak dağıtılmasını sağlar ve merkezi bir şirketin makine dağıtımına yatırım yapması gerekmez, böylece daha merkeziyetsiz olur ve şirketin sıfır sunucu maliyeti olur',
      desc4: 'Akıllı sözleşmeler ve dijital para stake edilerek yatırımcılar 5-10 yıl beklemek yerine yaklaşık 1 yıl içinde çıkabilir ve getiri elde edebilirler',
      desc5: 'Kullanıcılar projelerin büyümesine katılabilir ve yayılmayı artırabilir',
      desc6: 'Kullanıcılar sınır tanımayan özgür ödemeleri gerçekleştirebilir',
      text1: 'Stake',
      text2: 'Herkes katılabilir',
      text3: 'Sıfır maliyet',
      text4: 'Getiri süresi kısaltılır',
      text5: 'Sınır yok',
      text6: 'Kullanıcılar bonus alır'
    },
    cont7: {
      title1: 'DeepLink Protocol’ün İş Modeli',
      desc1: "Düşük gecikmeli bulut oyun deneyimi sağlamak için, teknoloji haricinde, GPU makinelerinin kullanıcıya 50 kilometre mesafede bulunması ve küresel kapsam için 50.000'den fazla veri merkezine ihtiyaç vardır.",
      title2: 'Geleneksel Merkezi Bulut Oyun Platformu',
      title3: 'DeepLink’in Blockchain Teknolojisine Dayalı Bulut Oyun Platformu',
      text1: 'Platform kendi makinelerini satın alır, bu büyük maliyetlere neden olur ve yatırımın geri dönüşü belirsizdir.',
      text2: 'Makinelerin kapsama alanı dar, küresel kapsama imkanı yoktur.',
      text3: 'Merkezi teknoloji, on binlerce veri merkezini destekleyemez.',
      text4: 'Madenciler ve internet kafe sahipleri GPU sunucuları sağlar ve madenciler token ödülleriyle yatırımlarını hızlı bir şekilde geri kazanır, bu da platformun makineleri satın alma baskısını azaltır.',
      text5: 'Madenci ve internet kafelerin sayısı fazla olduğundan küresel kapsama imkanı sağlar.',
      text6: "Dağıtılmış blockchain teknolojisi, 100.000'den fazla veri merkezi ve 10 milyondan fazla GPU sunucusunu destekler."
    },
    cont8: {
      title1: 'DeepLink Token Uygulama Senaryoları',
      text1: 'DLC Satın Alma',
      text2: 'DLC Ödeme', 
      text3: 'USDT Ödeme',
      text4: 'DLC Ödeme',
      text5: "DEX'e DLC ve USDT Enjekte Etme", 
      text6: 'DLC Satışı',
      text7: 'Kullanıcı 1',
      text8: 'DeepLink Yazılımı', 
      text9: "Bunların %40'ı zincir üzerinde doğrudan yok edilecek",
      text10: '1. NFT Satın Alma',
      text11: '2. Bulut Oyun Süresi Satın Alma', 
      text12: '3. İnternet Kafe Makinesi Süresi Kiralama',
      text13: '4. Kişisel Paylaşımlı Oyun Süresi Satın Alma', 
      text14: "Bunların %40'ı DEX üzerinden DLC satın alınıp yok edilecek", 
      text15: 'NFT Satın Alma',
      text16: 'DEX ve CEX',
      text17: 'Diğer Ticaret Platformları',
      text18: 'Madenci',
      text19: 'Likidite Madenciliği Ödülleri',
      text20: '2 yıl boyunca ödüller, her yıl 5 milyar DLC'
    },
    cont8_new: {
      text7: 'Bireysel kullanıcılar',
      text12: '3. İnternet kafede makine kiralama süresi',
      text21: 'Ticari kullanıcılar',
      text22: 'DeepLink Protokolü',
      text23: '1. Lisans satın al',
      text24: '2. %20-30 işlem ücreti',
      text25: "DLC'nin %100'ü doğrudan zincirde yok edilecek"
    },
    cont8_new1: {
      title: 'Üst düzey YouTubers projemizi destekliyor',
      text1: '',
      text2: 'İş kullanıcıları',
      text3: 'DeepLink Protokolü',
      text4: '1. Lisans satın al',
    },
    cont9: {
      title1: 'DeepLink Token Likidite Madenciliği Ödülleri',
      text1: 'Kullanıcı 1',
      text2: 'DEX’e DLC ve USDT yatırma', 
      text3: 'Likidite madenciliği ödülleri',
      text4: '2 yıl süreyle, her yıl 5 milyar DLC ödül',
      text5: 'Uniswap ve Pancake Swap’a DLC ve USDT likiditesi ekleme', 
      text6: 'Her gün bir kez ödül dağıtımı, likidite NFT’si aracılığıyla otomatik tahsil',
      text7: 'Ödüllerin %80’i likidite sağlayıcılara, %20’si trader’lara verilecek',
      text8: 'Günlük 13,698,630 DLC ödülü'
    },
    cont9_new: {
      title1: 'DeepLink Token Likidite Madenciliği Ödülleri',
      text1: 'DLC’nin %5’i ve eşdeğerdeki USDT, ETH ve BNB.',
      text2: 'DEX’e enjekte etmek',
      text3: 'DEX’in likiditesini artırmak',
      text4: '',
      text5: 'Bu %5 token, IDO (Initial DEX Offering) sırasında satılacak. Gerçekte satılan orana bağlı olarak, sonunda hepsi DEX’e enjekte edilecek.',
      text6: 'DLC ve USDT’nin likiditesini Uniswap, SushiSwap, Pancake Swap gibi DEX’lere enjekte etmek',
      text7: 'Bu likidite 5 yıl süreyle kilitlenecek ve sonrasında projenin uzun vadeli gelişimi için kullanılacak.',
      text8: ''
    },
    cont10: {
      title: 'Yol Haritası & Planlar',
      desc1: 'Ekim 2020',
      text1: 'DeepLink ürün projesinin başlatılması',
      desc2: 'Mart 2021',
      text2: 'Tohum yatırımının alınması',
      desc3: 'Ağustos 2021',
      text3: "İlk DeepLink iç geliştirme sürümünün yayınlanması, teknik gecikme 3ms'e ulaştı, oyun klavyesi ve mouse desteği",
      desc4: 'Mayıs 2022',
      text4: 'İlk DeepLink uzaktan kontrol özelliği test sürümünün yayınlanması, 3K 144HZ ekran desteği',
      desc5: 'Ağustos 2022',
      text5: 'İkinci DeepLink uzaktan kontrol özelliği test sürümünün yayınlanması, sanal ekran desteği',
      desc6: 'Ekim 2022',
      text6: "DeepLink'in resmi sürümünün yayınlanması, Korece, Çince ve İngilizce desteği ve DeepLink'i işletmek üzere Singapur'da DeepCloudX şirketinin kurulması",
      desc7: 'Aralık 2022',
      text7: 'Melek yatırımının alınması',
      desc8: 'Nisan 2023',
      text8: "Kore'nin en büyük internet kafe zinciriyle işbirliği, kafede gerçek bir örnek odanın kurulması, bulut internet kafe testleri, kullanıcılardan olumlu geri dönüşler alınması",
      desc9: 'Haziran 2023',
      text9: "DeepLink indirme sayısının 200,000'i aşması, kullanıcıların 100'den fazla ülkede bulunması, 8K 60Hz ekran desteği",
      desc10: 'Ocak 2021',
      text10: "DeepLink'in Ar-Ge yönünün belirlenmesi ve ürün tasarımı ve geliştirilmesine başlanması",
      desc11: 'Nisan 2021',
      text11: "Ana ekip tamamlandı, ana ekip üyeleri 2011'den beri bulut oyun endüstrisinde, Cisco, IntelNvidia, Huawei, Shunwang gibi şirketlerden gelen kurucu ekip",
      desc12: 'Mart 2022',
      text12: "DeepLink'in internet kafe alanında testlere başlaması, teknik gecikmenin 1ms'e indirilmesi",
      desc13: 'Temmuz 2022',
      text13: 'Üçüncü DeepLink uzaktan kontrol özelliği test sürümünün yayınlanması, gizlilik ekranı, uzaktan dosya kopyalama desteği',
      desc14: 'Eylül 2022',
      text14: 'Dördüncü DeepLink uzaktan kontrol özelliği test sürümünün yayınlanması, çoklu yayın, 444 gerçek renk desteği',
      desc15: 'Kasım 2022',
      text15: "Seul'de 10 mini PC kurularak sanal internet kafe ortamının oluşturulması ve bulut internet kafe testleri",
      desc16: 'Şubat 2023',
      text16: "DeepLink indirme sayısının 100,000'i aşması, kullanıcıların 50'den fazla ülkede bulunması, 4K 200HZ ekran desteği",
      desc17: 'Mayıs 2023',
      text17: "DeepLink indirme sayısının 150,000'i aşması, kullanıcıların 80'den fazla ülkede bulunması, DLC cüzdan özelliği, Vietnamca, Japonca desteği"
    },
    cont11: {
      title: 'Gelişim Planı',
      desc1: 'Temmuz 2023',
      text1: 'Cihaz listesi ekleme ve paylaşım ödülleri özelliği desteği',
      desc2: 'Ağustos 2023',
      text2: "Seul'de ilk bulut internet kafesinin açılması",
      desc3: 'Ekim 2023',
      text3: 'Paylaşım ödülleri özelliği desteği',
      desc4: 'Aralık 2023',
      text4: 'NFT özelliği desteği',
      desc5: "2024'ün 1. Çeyreği",
      text5: "WEB sürümü için oyun paylaşım özelliği desteği ve ortaklık kurulması, hedef DeepLink'in 500.000'den fazla indirilmesi",
      desc6: "2024'ün 2. Çeyreği",
      text6: "Hedef DeepLink'in 1.000.000'den fazla indirilmesi ve 10 bulut internet kafesi ile işbirliği",
      desc7: "2024'ün 3. Çeyreği",
      text7: "Hedef DeepLink'in 2.000.000'den fazla indirilmesi, 3000 paylaşımlı bilgisayar ve 50 bulut internet kafesi ile işbirliği",
      desc8: "2024'ün 4. Çeyreği",
      text8: "iOS, Mac, Vision Pro AR sürümleri desteği, hedef DeepLink'in 3.000.000'den fazla indirilmesi, 10.000 paylaşımlı bilgisayar, 150 bulut internet kafesi ile işbirliği",
      desc9: '2025 Yılı',
      text9: "Çok markalı VR ve AR cihazları desteği, hedef DeepLink'in 6.000.000'den fazla indirilmesi, 20.000 paylaşımlı bilgisayar, 300 bulut internet kafesi ile işbirliği",
      desc10: '2026 Yılı',
      text10: "Hedef DeepLink'in 15.000.000'den fazla indirilmesi, 50.000 paylaşımlı bilgisayar, 800 bulut internet kafesi ile işbirliği"
    },
    cont12: {
      text: "DBC zincirinde 60 milyar, ETH zincirinde 30 milyar, BSC'de 10 milyar basıldı",
      text1: 'Finansman',
      text1_1: 'Tohum Turu',
      desc1_1: 'CEX borsasında listelendikten 30 gün sonra başlayan, 20 ay sürecek olan aylık %5 oranında kilidin açılması',
      text1_2: 'Melek Yatırımcı Turu',
      desc1_2: 'CEX borsasında listelendikten 30 gün sonra başlayan, 10 ay sürecek olan aylık %10 oranında kilidin açılması',
      text1_3: 'A Turu',
      desc1_3: 'CEX borsasında listelendikten 30 gün sonra başlayan, 10 ay sürecek olan aylık %10 oranında kilidin açılması',
      text1_4: 'PreSale ve Public Sale',
      desc1_4: "Listelendikten sonra %40'ı hemen açılır, geri kalanı 30 Ağustos'tan itibaren 6 ay süreyle aylık %10 oranında açılır. Toplanan fonların %10 ile %30'u DEX likiditesine eklenir (3 yıl kilitli)",
      text2: 'Takım',
      desc2: 'CEX borsasında listelendikten 60 gün sonra başlayan, 20 ay sürecek olan aylık %5 oranında kilidin açılması',
      text3: 'Topluluk Airdrop',
      desc3: 'Belirli görevleri tamamlayıp DID KYC doğrulamasından geçtikten sonra airdrop alınabilir',
      text4: 'NFT Satışı',
      desc4: 'NFT, yazılım lisans haklarını bağlar ve TOKEN hediye eder, Token CEX borsasında listelendikten sonra kilidi açılmaya başlar',
      text5: 'Madencilik',
      desc5: 'GPU gücü sağlayan madencilere ödül olarak %16 pay kullanılır, ek olarak %4 bant genişliği madenciliği için kullanılır, bu kısım her 4 yılda bir yarıya iner. Madencilik, CEX borsasında listelendikten 30 gün sonra başlar.',
      text6: 'Vakıf',
      desc6: 'Tokenler, CEX borsasında listelendikten 60 gün sonra açılmaya başlar, toplam açılma süresi 2 yıldır, her çeyrek %12.5 açılır.',
      text7: 'Danışmanlar ve Hukuk',
      desc7: 'Farklı ülkelerin hukuki sorunlarını çözmek ve danışman ödülleri için kullanılır, kilidin açılması CEX borsasında listelendikten 30 gün sonra başlar, 10 ay süreyle her ay %10 oranında açılır.',
      text8: 'Toplam',
      text9: 'Miktar (milyar):',
      text10: 'Açıklama:',
      text11: 'Dolaşımda (milyar):',
      text12: 'Dolaşımda olmayan (milyar):',
      text13: '100 milyar'
    },
    cont13: {
      title: 'Ekip ve Danışmanlar',
      intro1: 'DeepLink CEO',
      intro2: "DeepLink'in kurucu ortağı",
      intro3: "DeepLink'in Operasyon Direktörü (COO)",
      intro4: "DeepLink'in İşletme Müdürü (CBO) ve Hindistan'da Pazar Lideri",
      intro5: 'Danışman',
      intro6: 'Danışman',
      intro7: 'Danışman',
      intro8: 'Kurucu Ortak ve Pazarlama Direktörü',
      user1_1: 'Yapay zeka alanında 13 yıllık, blockchain ve dağıtık bilgi işlem ağı teknolojileri geliştirmede 7 yıllık deneyime sahip bir seri girişimci.',
      user1_2: "2008 yılında Çin Okyanus Üniversitesi'nden bilgisayar bilimi bölümünden mezun oldu.",
      user1_3: "2012 yılında Jushang Intelligence'ı kurdu ve dünyanın ilk Çin yapay zekalı sesli asistanı 'Smart 360'ı geliştirdi",
      user1_4: 'Yazılım üç yıl içinde 17 milyondan fazla indirildi. 2014 yılında dünyanın ilk yapay zeka hoparlörü "Xiaozhi Speaker"ı piyasaya sürdü.',
      user1_5: "Mayıs 2017'de DeepBrainChain'i kurarak yüzlerce AI, bulut oyunu ve bulut internet kafe müşterisine hizmet vermektedir.",
      user2_1: 'Seri girişimci, deneyimli bulut oyun uzmanı. Daha önce Shunwang Bulut Oyun Enstitüsü müdürü olarak teknoloji geliştirmeden sorumluydu.',
      user2_2: "1999 yılında Zhejiang Üniversitesi Bilgisayar Bilimleri bölümünden mezun oldu. 2001-2004 yılları arasında Zhejiang LanDe'de telekomünikasyon alanında çalıştı.",
      user2_3: "2009 yılında Çin'in ilk çift çekirdekli tarayıcısı 'Sunflower Browser'ı kurdu.",
      user2_4: "2010-2021 yılları arasında halka açık şirket Shunwang'a katıldı ve Shunwang Cloud Computer'ı kurdu, Shunwang Enstitüsü'nün müdürü olarak görev yaptı. Bulut internet kafeler için çözümler sağladı ve bulut oyunlarını yöneterek şirketini Çin'de bulut oyun çözümleri sunan ilk şirket haline getirdi.",

      user3_1: 'Eğitim geçmişi: 2007 Pekin Üniversitesi Çince Bölümü',
      user3_2: '2023: DeepLink Operasyon Direktörü',
      user3_3: "2022-2023: DeepBrain Chain CEO'su",
      user3_4: '2019-2021: CELLOGIN Uluslararası Pazar Lideri',
      user3_5: "2018: FLOW FACTORY CEO'su",
      
      user4_1: "Eğitim ve Sertifikalar: - 2020 yılında Mysore Üniversitesi'nden İşletme Yönetimi lisans derecesi.",
      user4_2: '- Dijital Pazarlama Sertifikası.',
      user4_3: '- Arama Motoru Optimizasyonu (SEO) Sertifikası.',
      user4_4: '- Blockchain Sertifikası.',
      user4_5: "Technic Constructions'da Satış ve Pazarlama: - Satış ve pazarlama rolünde görev alarak üç ay içinde 500.000 dolarlık satış gerçekleştirdi.",
      user4_6: "BYJU'S'ta İş Geliştirme Asistanı: - 2020-2021 yılları arasında çalıştı.",
      user4_7: "- 10 milyar dolarlık bir unicorn startup'ta kilit rol oynayarak büyümeyi teşvik etti, ortaklıklar kurdu ve eğitim teknolojisi programlarının işleyişini basitleştirdi.",
      user4_8: "Space Inn'in Kurucu Ortacortığı: - Girişimcilik becerileri, stratejik düşünce ve iş dünyası sezgisini geliştirmeye odaklanan bağımsız bir proje kurdu.",
      user4_9: 'Hisse Senedi Piyasası Yatırım/Ticaret: - Hisse senedi piyasasında yatırım ve ticaret konusunda beş yıllık deneyim birikimi, finansal piyasalarda yatırım fırsatlarını yönlendirme ve değerlendirme uzmanlığını sergiledi.',
      user4_10: 'Kurumsal Şirketlerde Staj: - Çeşitli kurumsal şirketlerde birden fazla staj tamamladı, kurumsal kültür, verimli süreçler ve etkili iş stratejileri hakkında değerli içgörüler elde etti.',
      user4_11: 'Kripto Para Endüstrisi Deneyimi: - Birden fazla kripto para ve NFT projesine katıldı, endüstride değerli deneyimler kazandı.',
      user4_12: "- Empowa'nın önemli Real-Fi Cardano projesine katkıda bulundu, gelişimini ve büyümesini teşvik etti. 2022'de Cardano projesi Empowa'ya katkıda bulunarak büyümesini teşvik etti.",
      user4_13: '- Şu anda DeepLink Cloud ile işbirliği yapıyor, kripto para alanındaki uzmanlığını genişletiyor.',

      user5_1: "2012 yılından bu yana Hyunjin ICT'de başkan yardımcısı olarak görev yapmaktadır, şirket 11 yıldır IoT ve iletişim hizmetleri sunmaktadır.",
      user5_2: 'Satış ve pazar yönetimi, kamu kurumlarında birçok büyük projeyi yürütme deneyimi.',
      user5_3: "Güney Kore'nin en büyük IT şirketi olan Samsung SDS'de 14 yıl (1998-2012) boyunca bilgi stratejisi danışmanlığı, iş yenilik projeleri ve yazılım geliştirme yapmıştır.",
      user5_4: "2017-2019 yılları arasında Coinzest kripto para borsasında çalıştı, Güney Kore'nin bir numarası ve dünya sıralamasında onuncu sırada yer aldı.",
      user5_5: "Hyunjin Venus'un kurucusu, şirket Güney Kore'deki büyük işletmeler ve kamu kurumları için beş yıldır bir refah alışveriş merkezi işletmektedir.",
      user5_6: 'PMP (Proje Yönetimi Profesyoneli) ve CISA (Kayıtlı Bilgi Sistemleri Denetçisi) sertifikalarına sahiptir.',
      
      user6_1: 'Eğitim geçmişi: 2003 yılı, Pekin Üniversitesi Guanghua Yönetim Enstitüsü',
      user6_2: "2023: MetABC'nin CEO'su",
      user6_3: "2020: HillstoneHub'un CEO'su",
      user6_4: "2019: Hillstone İş Merkezi'nin CEO'su",
      user6_5: "2019: Hillstone Global (blockchain) COO'su",
      user6_6: "2017: Foundersbridge'in kurucusu",
      user6_7: "2017: Hillstone Partners (özel sermaye) ortağı",
      user6_8: "2014: BrightA Consulting'in kurucu ortağı",
      user6_9: '2012: Kıdemli Danışman (Çin İş Uzmanı)',

      user7_1: "Joseph Alexander, Deep Brain Chain için özelleştirilmiş büyük ölçekli dil modeli ChatDBC.com'un yaratıcısıdır.",
      user7_2: "Uzmanlık alanı, DeepLink ve DBC'nin Kuzey Amerika'daki iş geliştirmesidir.",
      user7_3: "Twitter'da '@ChatDBC' hesabını takip ederek DeepLink ve DBC hakkındaki tüm güncel haberleri alabilirsiniz.",
      
      user8_1: "Maryna Barysheva, derin teknoloji, DeFi, altyapı ve oyun projelerinde uzmanlaşmış, ödüllü Web3 pazarlama ajansı LKI Consulting'in COO'sudur.",
      user8_2: "23'ten fazla ülkede blockchain projelerini başarıyla genişletti, 1 milyon ile 10 milyon dolar arasında pazarlama bütçelerini yönetti ve 100 milyon dolarlık bir B2B teknoloji startup'ının pazarlamasını yürüttü.",
      user8_3: "Maryna'nın uzmanlık alanları topluluk geliştirme, marka bilinirliği ve global GTM stratejileridir.",
      user8_4: "O, dünya çapında kripto para konferanslarında sıkça konuşmacı olarak yer alan ve Helsinki Üniversitesi'nde yapay zeka, teknoloji ve blockchain projeleri için bir kuluçka programı mentorudur.",
      
      user9_1: 'Brando, kripto para alanında deneyimli bir isimdir. 2016 yılında kripto paraları araştırmaya başlamış ve bu, meşhur 2017 boğa piyasasına yol açmıştır.',
      user9_2: "2012 yılında Bigger Than Race™️'i kurdu ve 2017 yılında mevcut adını aldı.",
      user9_3: "Onun nitelikleri, Metaverse'de yenilik ve gelişimi yönlendiren bir risk sermayesi fonu olan ve IBM'den blockchain ve bitcoin sertifikaları içeren EveryRealm'den aldığı Web3/Metaverse sertifikasını içerir.",
      user9_4: 'Kripto dehası BRANDO MURPHY',
      user9_5: 'Ana vizyonu ve amacı, insanların blockchain teknolojisi aracılığıyla finansal özgürlüğe ulaşmalarına yardımcı olmak ve dördüncü ve beşinci sanayi devrimlerinin yeni ekonomisinin altyapısına yatırım yapmalarını ve sahip olmalarını sağlamaktır. BTR topluluğu VC, küresel sadık üyelerin geleceğin teknolojisine sahip olmalarına yardımcı olmaktadır.',
      user9_6: "2024 yılında, dünya çapındaki oyunculara adil bir rekabet ortamı sağlayan yapay zeka bulut oyun teknolojisi DeepLink Protocol'ün kurucu ortağı ve CMO'su oldu. Memleketi Kaliforniya, Los Angeles'tır.",
      
      user10_1: "Summer, kripto projeleri, yatırım stratejileri ve daha fazlasını kapsayan bir medya şirketi olan FireHustle'ın kurucusu ve sunucusudur. Summer, işletme eğitimi geçmişine sahip olup yayıncılık ve iletişim alanında zengin deneyime sahiptir.",
      user10_2: "Medya portföyü, 44,000'den fazla aboneye sahip bir YouTube kanalı, 3,000'den fazla üyeye sahip özel bir Discord topluluğu, özel bir üye grubu ve daha fazlasını içerir.",
      user10_3: "Yatırım açısından, FireHustle kişisel olarak 50'den fazla web3 startup'ına yatırım yapmış ve birçok kripto projesi, risk sermayesi şirketi, başlangıç platformu, sendika, KOL grubu vb. için pazarlama, fon toplama ve danışmanlık ortağı olarak görev yapmaktadır.",
      
      user11_1: "Manny, on yılı aşkın sektör deneyimi ile blockchain tutkunu ve uzmanıdır ve 2013'ten bu yana lider şirketlere ve programlara kat Contributing to leading companies and programs since 2013.",
      user11_2: "Mesleki geçmişi, Grayscale'de başlangıç pozisyonları, Coinbase operasyon ekibi, DYDX ve Ledger pazarlama ekipleri ve son olarak Binance US'teki pozisyonları içerir.",
      user11_3: "Eğitim: ABD'deki önde gelen kurumlarında (özellikle Stanford Üniversitesi) blockchain ve kripto para birimi ile ilgili kurslar tamamlandı. Kurslar 'Blockchain ve Kripto Para Birimi: Kapsamlı Giriş', 'İleri Blockchain Stratejileri', 'CEO Programı' ve 'CMO Programı'nı içerir. Finans ve işletme yönetimi alanında uzmanlaşmıştır.",
    },
    cont14: {
      title: 'Yatırım Kuruluşlarımız',
      organ1: "DeepBrain Chain Vakfı 2017 yılında kuruldu. Ocak 2018'de DBC tokeni Huobi borsasında listelendi. Ana ağı 2021 yılında başlatıldı. DeepBrain Chain, GPU odaklı yüksek performanslı dağıtık bir hesaplama ağıdır. Vizyonu, AI+metaverse çağı için en önemli altyapı olacak blockchain tabanlı, sonsuz ölçeklenebilir yüksek performanslı bir hesaplama ağı oluşturmaktır. Şu anda yapay zeka, bulut oyunları ve film renderleme gibi alanlarda GPU hesaplama hizmeti sunmaktadır. Halen yüzlerce yapay zeka ve bulut oyunları ile ilgili şirket ve 30.000'den fazla yapay zeka uzmanına hizmet vermektedir.",
      organ2: "Gobi Capital, Şanghay, Pekin ve Güneydoğu Asya'da merkezi bulunan, Çin'deki erken aşama teknoloji projelerine odaklanan profesyonel bir risk sermayesi şirketidir. Stratejik yatırımcıları arasında IBM, Sierra Ventures, McGraw-Hill ve Disney'nin girişim sermayesi kolu Steamboat Ventures bulunmaktadır. Gobi, Tuniu, Camera360 ve Zhu Yun gibi tanınmış start-up'lara yatırım yapmıştır.",
      organ3: "Hycons, DBC teknolojisine dayalı olarak geliştirilen ve Hyunjin ICT tarafından işletilen bir GPU hesaplama bulut platformudur. Hycons, DBC tabanlı dağıtık yüksek performanslı ağ teknolojisi ve DeepLink tabanlı düşük gecikmeli HD akış teknolojisi ile öne çıkmaktadır. Platform, işletmeler, okullar, araştırma kurumları ve franchise işletmecileri için üyelik yönetimi, kayıt ve ödeme dahil kapsamlı hizmetler sunmaktadır. Kore'de 1.000'den fazla GPU kartı ve 2 merkezi işleten Hycons, gelecekte 5 merkeze genişlemeyi planlamaktadır. Ayrıca, Hycons internet kafe franchise işinde test uygulamaları yapmaktadır. İleri teknoloji altyapısı, rekabetçi bulut hizmet ücretleri ve otomasyon yetenekleri ile Hycons, dünya lideri bir GPU hesaplama platformu olmayı hedeflemektedir.",
      organ4: "ROCK Capital, 2018 yılında kurulan profesyonel bir kripto para fonudur ve merkezi Güney Kore'dedir. Finansal yatırım, blockchain ve merkeziyetsizlik uzmanlarını bir araya getirerek yerel ve uluslararası çeşitli şirketlerle, küresel projeler, hükümetler, kurumlar ve diğer ekonomik varlıklarla geniş bir işbirliği yürütmektedir. 100'den fazla projeyi başarıyla tamamlamış ve gelecekteki finansın çeşitli alanlarını keşfetmiştir. ROCK, işbirliği, büyüme, aktivasyon ve ortaklıkları güçlendirerek çeşitli pazar ortamlarında zengin bir deneyim ve uzmanlığa sahiptir. Sabit ilkeleriyle ROCK, yüksek getiri sağlamayı, yatırım fırsatlarını genişletmeyi ve sermaye faaliyetlerini maksimize ederek somut müşteri deneyimleri yaratmayı amaçlamaktadır.",
      organ5: 'Bu risk sermayesi şirketi, yükselen teknolojilerdeki merkeziyetsiz blockchain üzerine odaklanarak dördüncü ve beşinci devrimi teşvik edecektir. Web3 alanı ve ötesindeki fırsatları yakalamalarına yardımcı olan geniş bir bilgiye sahiptir. Bloktopia, Animoca Brands, Stripe gibi tanınmış markalarla ortak yatırım yapmakta ve Singularity ağı, Shiba ekosistemi ve çeşitli oyun stüdyolarıyla sağlam ilişkiler kurmuşlardır. BTR-Army geçişine sahip olanlar bu şirketin hizmetlerinden yararlanabilir.'
    },
    cont15: {
      title: 'Topluluk Etkinlikleri',
      
    }
  },
  coverage: {
    title: 'DeepLink yazılımı küresel kullanıcı dağılım haritası',
    text: 'Toplam DeepLink Kullanıcı Sayısı:'
  },
}