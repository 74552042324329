// 法语
export default {
  meau: {
    nav: "Page d'accueil",
    "nav1": "Acheter des nœuds de minage",
    "nav2": "Course GPU Orion",
    "nav3": "",
    nav3: 'Logiciel DeepLink',
    nav3_1: 'Téléchargement de logiciel',
    nav3_2: 'Licence logicielle',
    nav4: 'Minage de bande passante',
    "nav5": "Cybercafé cloud",
    "nav6": "Blog",
    nav7: 'Docs',
  },
  reward2: {
    desc: {
      title1: 'Nœuds DeepLink',
      text1: 'Seuls les propriétaires de nœuds peuvent participer au minage de trafic CPU et au minage de machines GPU personnelles. La récompense totale est de 12% en jetons DLC, dont 4% pour les nœuds de trafic CPU et 8% pour les nœuds GPU personnels. Le nombre total de jetons est de 12 milliards. La production par nœud dépend du nombre de nœuds effectivement vendus. Si tous sont vendus, chaque nœud produira 100 000 DLC. Si seulement 10% des nœuds sont vendus, chaque nœud produira 1 million de DLC.',
      title2: 'Nombre total de nœuds: 120 000, pas d’émission supplémentaire',
      text2: 'Airdrop supplémentaire par nœud',
      text3: 'Quantité minimale de DLC minée par nœud',
      text4: 'Prix initial',
      text5: 'Prix maximum'
    },
    btn_text: 'Acheter des nœuds',
    table: {
      tier: 'Niveau',
      license: 'Prix de la licence de nœud (USDT)',
      total_num: 'Total des licences',
      total_usdt: 'Total USDT',
      buy: 'Acheter'
    },
    intro: {
      title: 'Introduction au minage de nœuds',
      text1_1: 'Plus le réseau DeepLink a d’utilisateurs, plus il nécessite de trafic réseau et de calculs, ce qui entraîne un besoin accru de machines de trafic CPU et de machines de calcul GPU pour fournir l’infrastructure au réseau DeepLink. Cela permet la décentralisation et aide DeepLink à se développer plus rapidement. En achetant une licence de nœud, vous avez la possibilité de participer au minage et de gagner des récompenses. Vous avez des questions ? Venez',
      text1_2: 'et',
      text1_3: 'discutez avec nous ci-dessus.',
      text2: "Chaque nœud est émis sous la forme d'un NFT et reçoit en plus un airdrop de 30 000 DLC avec la chaîne BSC. L'airdrop commence avant la cotation sur la bourse, avec 20 % des DLC airdrop débloqués immédiatement et 80 % débloqués sur 8 mois. \n (Afficher le processus de jeton DLC : ouvrez le portefeuille et sélectionnez le réseau BSC, sélectionnez Ajouter un jeton et saisissez l'adresse du contrat : 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC)",
      text3: 'Les nœuds ne peuvent pas être revendus à d’autres avant le 31 octobre 2024.',
      text4: '25% des tokens minés sont débloqués immédiatement, tandis que 75% sont débloqués linéairement sur 150 jours.',
      text5: 'Si le minage ne commence pas dans les 10 jours suivant la cotation, les récompenses seront distribuées sous forme d’airdrops, qui auront lieu tous les 7 jours. Les règles des airdrops sont les mêmes que celles du minage, avec une production totale quotidienne de 4,1 millions de DLC, répartie également en fonction du nombre de nœuds vendus. 25% sont débloqués immédiatement, et 75% sont débloqués linéairement sur 150 jours. Si le minage a déjà commencé, des machines correspondantes seront nécessaires pour recevoir des récompenses.',
      text6: "Pour les achats de plus de 20 nœuds, les opérations sur les nœuds peuvent être déléguées. Les investisseurs qui achètent plus de 1 000 nœuds individuellement bénéficieront d'un ensemble complet de services d'assistance pour l'exploitation des nœuds, ainsi que de la possibilité de s'arrimer à un opérateur coopératif pour les aider à gérer les nœuds.",
      text7: 'Une machine virtuelle peut prendre en charge 20 nœuds NFT et les revenus sont multipliés par 20.',
      text8: 'Achetez un nœud DeepLink et participez à la compétition de puissance de calcul Orion avec 9 millions de dollars: '
    },
    question: {
      ques1: 'Combien de NFT de nœuds sont vendus au total ?',
      ans1: 'Un total de 120 000',
      ques2: 'Combien coûte chaque NFT de nœud ?',
      ans2: 'Il est divisé en 10 niveaux, avec des prix allant de 30U à 155U, vendus sur le site officiel jusqu’à épuisement des stocks',
      ques3: 'L’achat d’un NFT de nœud est-il un paiement unique ?',
      ans3: 'Oui, l’achat d’un nœud est un paiement unique, aucun frais supplémentaire n’est requis à l’avenir',
      ques4: 'Y a-t-il des airdrops supplémentaires lors de l’achat d’un NFT de nœud ?',
      ans4: 'Oui, chaque propriétaire de nœud reçoit un airdrop de 30 000 DLC. L’airdrop commence avant la cotation en bourse, avec 20% des DLC débloqués immédiatement et 80% débloqués sur 8 mois',
      ques5: 'Quel est le mécanisme de minage spécifique ?',
      ans5: 'La récompense totale est de 12 milliards de DLC. Les revenus totaux pour les nœuds de trafic sont de 4 milliards de DLC, et les revenus totaux pour les nœuds de machines GPU personnelles sont de 8 milliards de DLC. En achetant un NFT de nœud, vous pouvez participer simultanément aux deux types de minage. La récompense pour les 4 premières années est de 1,5 milliard de DLC par an, avec une réduction de moitié tous les 4 ans. La récompense totale quotidienne est de 4,11 millions de DLC, distribuée uniquement aux propriétaires de NFT de nœuds. 25% des jetons minés sont débloqués immédiatement, 75% sont débloqués linéairement sur 150 jours',
      ques6: 'Un NFT de nœud acheté peut-il participer simultanément au minage de machine à trafic CPU et au minage de machine GPU personnelle ?',
      ans6: 'Oui',
      ques7: 'Qu’est-ce qu’un nœud de machine à bande passante CPU ?',
      ans7: 'Le nœud de machine à bande passante CPU assume la tâche principale de calcul et de transmission du trafic dans le réseau DeepLink. Grâce au modèle de minage, DeepLink permet à un plus grand nombre de personnes de participer et de gagner des récompenses. Les revenus totaux pour les nœuds de trafic sont de 4 milliards de DLC',
      ques8: 'Quelle est la récompense totale pour les machines à bande passante au cours de la première année ?',
      ans8: 'La récompense pour la première année est de 500 millions de DLC, avec environ 1,37 million de DLC récompensés chaque jour',
      ques9: 'Comment la bande passante de chaque nœud de machine à bande passante CPU est-elle liée à ses revenus ?',
      ans9: 'La bande passante de base est de 10Mb en bande passante montante. La valeur de la bande passante est de 10 pour une bande passante montante de 10Mb. La bande passante n’est valide qu’en multiples de 10, par exemple, une bande passante montante de 45Mb est calculée comme 40 en valeur de bande passante. La bande passante augmente de 30% selon l’utilisation réelle. Enfin, les revenus sont répartis en fonction de la valeur réelle de la bande passante',
      ques10: 'Les exigences de configuration pour les nœuds de machine à bande passante sont-elles élevées ? Y a-t-il des informations de configuration ? Comment participer au minage ?',
      ans10: 'Les exigences de configuration ne sont pas élevées. vCPU 1C + 2G de mémoire + 50G de disque dur. La location sur AWS coûte environ 10 USD par mois, la bande passante est facturée en fonction de l’utilisation réelle. Après avoir loué une machine virtuelle CPU sur AWS, installez le programme de minage de nœuds de trafic DeepLink, créez une adresse de portefeuille, transférez le NFT de nœud dans le portefeuille et activez la fonction de minage pour participer au minage',
      ques11: 'Qu’est-ce qu’un nœud de machine GPU personnelle ?',
      ans11: 'Les particuliers peuvent offrir leurs ordinateurs GPU pour participer au minage. Les machines GPU fournissent des services d’infrastructure de jeu en nuage pour le protocole DeepLink. Les revenus totaux pour les nœuds de machines GPU personnelles sont de 8 milliards de DLC',
      ques12: 'Quelle est la récompense totale pour les nœuds de machines GPU personnelles au cours de la première année ?',
      ans12: 'La récompense pour la première année est de 1 milliard de DLC, avec environ 2,74 millions de DLC récompensés chaque jour',
      ques13: 'Quelles sont les exigences de configuration pour les nœuds de machines GPU personnelles ? Comment participer au minage ?',
      ans13: 'Les exigences de configuration pour les machines GPU sont les suivantes : fréquence CPU d’au moins 3,0 GHz, GPU de la série Nvidia Geforce : 20xx, 30xx, 40xx, et au moins 16G de mémoire'
    }
  },
  BandWidth: {
    "title": "Règles de minage de la bande passante du trafic",
    "text1": "Le minage de la bande passante du trafic offre un total de 4 milliards de DLC en récompenses, avec 500 millions de DLC récompensés chaque année pendant les quatre premières années, et une division par deux tous les quatre ans.",
    "cont2": {
      "title": "Règles de participation",
      "text1_1": "La possession d'un NFT de nœud est requise (chaque nœud accorde un airdrop de 30 000 DLC, d'une valeur de 90$, en savoir plus sur les nœuds : ",
      "text1_2": "). Une seule machine CPU peut détenir plusieurs nœuds, et la récompense en tokens est proportionnelle au nombre de nœuds. Le nombre maximum de NFT de nœuds qu'une seule machine peut détenir est de 20.",
      "text2_1": "Exigences minimales pour les serveurs CPU : \n Les paramètres du serveur de calcul doivent répondre aux exigences minimales",
      "text3_1": "CPU",
      "text3_2": "vCPU 1C",
      "text4_1": "Mémoire",
      "text4_2": "2G",
      "text5_1": "Disque dur :",
      "text5_2": "Disque dur de 50G",
      "text6_1": "Bande passante réseau :",
      "text6_2": "La bande passante de base est de 10Mb en upload ; il est recommandé d'ajouter 1C de CPU et 2G de mémoire pour chaque augmentation de 200M de bande passante"
    },
    cont3: {
      title: 'Règles des zones de minage de trafic',
      text: 'Le minage de trafic divisera le monde en environ 130 régions, à chacune desquelles un nombre différent de récompenses DLC totales sera attribué, la formule des récompenses régionales est la suivante : récompenses régionales = valeur régionale * récompense quotidienne / valeur totale régionale',
      text1: 'La liste des régions est la suivante :',
      text2: 'Asie',
      text3: 'Amériques',
      text4: 'Europe',
      text5: 'Afrique',
      text6: 'Océanie',
      li_other: 'Autres',
      li1: 'Chine',
      li1_1: 'Chine du Nord',
      li1_2: 'Chine du Nord-Est',
      li1_3: "Chine de l'Est",
      li1_4: 'Chine Centrale',
      li1_5: 'Chine du Sud',
      li1_6: 'Chine du Sud-Ouest',
      li1_7: 'Chine du Nord-Ouest',
      li1_8: 'Taïwan, Chine',
      li1_9: 'Hong Kong, Chine',

      li2: 'Inde',
      li2_1: 'Uttar Pradesh',
      li2_2: 'Maharashtra',
      li2_3: 'Bihar',
      li2_4: 'Indonésie',
      li2_5: 'Pakistan',
      li2_6: 'Bangladesh',
      li2_7: 'Japon',
      li2_8: 'Philippines',
      li2_9: 'Vietnam',
      li2_10: 'Turquie',
      li2_11: 'Thaïlande',
      li2_12: 'Corée du Sud',
      li2_13: 'Malaisie',
      li2_14: 'Arabie Saoudite',
      li2_15: 'Émirats Arabes Unis',

      li3: 'États-Unis',
      li3_1: 'Californie',
      li3_2: 'Texas',
      li3_3: 'Floride',
      li3_4: 'New York',
      li3_5: 'Pennsylvanie',
      li3_6: 'Illinois',
      li3_7: 'Ohio',
      li3_8: 'Géorgie',
      li3_9: 'Michigan',
      li3_10: 'Caroline du Nord',
      li3_11: 'Autres régions des États-Unis',
      
      li4_1: 'Mexique',
      li4_2: 'Canada',
      li4_3: 'Brésil',
      li4_4: 'Colombie',
      li4_5: 'Argentine',
      
      li5: 'Russie',
      li5_1: 'Moscou',
      li5_2: 'Saint-Pétersbourg',
      li5_3: 'Autres régions de Russie',
      li5_4: 'Allemagne',
      li5_5: 'Royaume-Uni',
      li5_6: 'France',
      li5_7: 'Italie',
      li5_8: 'Espagne',
      li5_9: 'Pays-Bas',
      li5_10: 'Suisse',
      
      li6_1: 'Nigéria',
      li6_2: 'Égypte',
      li6_3: 'Afrique du Sud',
      
      li7_1: 'Australie',
    },
    cont5: {
      title: 'Mécanisme de Récompense (Basé sur la chaîne publique DBC AI compatible EVM DLC, URL du navigateur : https://test.dbcscan.io/)',
      text1: "Pendant les quatre premières années, la récompense annuelle d'extraction est de 500 millions de DLC, soit quotidiennement 1,369 million de DLC. Chaque région se voit attribuer des récompenses en DLC selon des proportions pondérées. Si une région n'a pas de machines, les récompenses en DLC pour cette région sont automatiquement détruites et ne sont attribuées à personne.",
      text2: "Le montant total des tokens récompensés chaque jour par région dans ce système est fixe. La récompense qu'une machine reçoit dépend de la bande passante de la machine, du temps d'extraction effectif Y, et du nombre de tokens misés. Plus le nombre de tokens misés est élevé, plus la récompense est importante, mais elle croît de manière non linéaire. La base de récompense est la même pour 0 à 10 000 pièces; les récompenses sont liées à une fonction quand elles dépassent 10 000 pièces.",
      text3: "Si la bande passante de trafic de la machine est utilisée, la valeur de puissance de calcul augmente de 30%, et le nombre de NFT de nœuds est un multiplicateur de la valeur de puissance de calcul. Actuellement, chaque machine peut détenir jusqu'à 20 NFT de nœuds.",
      text4: 'Seules les machines possédant des NFT de nœuds sont éligibles pour les récompenses en ligne.',
      text5: "Actuellement, une machine peut lier jusqu'à 20 NFT de nœuds, plus le nombre de NFT est élevé, plus les récompenses sont importantes, montrant une relation linéaire.",
      text6: "Pour les premiers 300 jours, une machine peut lier jusqu'à 20 NFT de nœuds.",
      text7: "Pour les premiers 600 jours, une machine peut lier jusqu'à 10 NFT de nœuds.",
      text8: "De 600 à 900 jours, chaque machine peut lier jusqu'à 5 NFT de nœuds.",
      text9: "De 900 à 1200 jours, chaque machine peut lier jusqu'à 2 NFT de nœuds.",
      text10: "De 1200 à 1800 jours, chaque machine peut lier jusqu'à 1 NFT de nœud.",
      text11: 'Après 1800 jours, chaque NFT de nœud peut lier 2 machines.',
      text12: 'Après 24 000 jours, chaque NFT de nœud peut lier 4 machines.',
      text13: 'Calcul des récompenses :',
      text14: 'R : Quantité totale de tokens de récompense quotidienne (valeur fixe)',
      text15: 'Le nombre de tokens misés de la i-ème machine',
      text16: 'La valeur de puissance de calcul de la i-ème machine',
      text17: "Le temps d'extraction effectif de la i-ème machine",

      text18: "Où N est le nombre total de machines participant à l'exploitation minière",
      text19: "La récompense pour 100 000 pièces est 1,23026 fois celle de 10 000 pièces, soit une augmentation d'environ 23,03%",
      text20: "La récompense pour 1 million de pièces est 1,46052 fois celle de 10 000 pièces, soit une augmentation d'environ 46,05%",
      text21: "La récompense pour avoir misé 10 millions de pièces est 1,69078 fois celle de 10 000 pièces, soit une augmentation d'environ 69,08%",
      text22: "La récompense pour avoir misé 100 millions de pièces est 1,92103 fois celle de 10 000 pièces, soit une augmentation d'environ 92,10%",
      
      title1: 'Réclamation des récompenses:',
      text1_1: 'Les récompenses peuvent être réclamées une fois toutes les 24 heures',
      text1_2: "Si aucun DLC n'est initialement misé ou si le montant misé est inférieur à 10 000, les gains s'accumuleront comme DLC misé jusqu'à atteindre 10 000 DLC",
      text1_3: "1) Les loyers s'accumuleront comme capital misé jusqu'à atteindre 10 000 DLC",
      text1_4: "2) La durée minimale de location est de 2 heures, sans limite de temps maximale; lorsque moins d'une heure de durée utilisable reste, la machine ne peut pas être louée et n'est pas comptée dans les récompenses en ligne. Les mineurs peuvent prolonger la durée à tout moment, mais chaque prolongation doit être d'au moins 2 heures",
      
      title2: 'Calcul des revenus quotidiens en DLC pour les machines: DLC calculé au prix de 0,003U,',
      text2_1: 'La bande passante de ma machine CPU:',
      text2_2: 'Nombre de NFT de nœuds:',
      text2_3: 'Région:',
      text2_4: 'Quantité de DLC misé:',
      text2_5: 'Bande passante totale:',
      text2_6: '(Calcul basé sur une moyenne de 10 NFT et un mise moyenne de 10 000 DLC)',
      text2_7: 'Veuillez sélectionner',
      title3: 'Revenus de récompenses sur la chaîne: {dlc_num} DLC (équivalent à {usdt_num} USD au prix actuel du DLC: 0,003 USD)',
      title4: 'Mécanisme de signalement et de vérification (Basé sur le DLC compatible EVM de la chaîne publique DBC AI)',
      text4_1: "La machine dispose de manière permanente d'un mécanisme de détection de la chaîne publique DBC AI, rapportant des informations au cluster de détection distribué DBC pour déterminer si la machine est en ligne; si elle n'est pas en ligne, les récompenses en ligne sont déduites. Si une interruption survient pendant l'utilisation par l'utilisateur, des DLC sont déduits de l'utilisateur. Limite de pénalité:",
      text4_2: "1) Si aucune pièce n'est misée, seule la récompense accumulée est pénalisée, jusqu'à 10 000 DLC",
      text4_3: "2) Si des DLC sont misés, jusqu'à 10 000 DLC peuvent être pénalisés",
    }
  },
  
  lanchpad: {
    cont1: {
      title1: 'DeepLink Protocol',
      desc: 'Protocole de jeu en cloud AI décentralisé',
      title2: 'DeepLink Protocol rend le développement de jeux cloud plus simple que jamais',
      btn1: 'Entrer dans PinkSale',
      btn2: 'Télécharger le Livre Blanc',
      btn3: 'Télécharger le Pitch Deck',
      btn4: 'Vente publique DeepLink',
      btn5: 'Bienvenue dans les Récompenses de Nœud',
      btn6: 'Télécharger le Client',
      desc1: 'Support DeepLink pour obtenir des Tokens DLC',
      desc2: '100% Sûr et Vérifié',
      desc3: 'Programme NVIDIA Inception'
    },
    cont2: {
      desc1: 'BATTLEFIELD',
      desc2: 'L\'efficacité affichée est un exemple de deux ordinateurs séparés de 50 km. L\'ordinateur de contrôle est un ordinateur portable classique, tandis que l\'ordinateur contrôlé est une machine haute performance avec GPU. Une image dynamique du jeu est affichée pendant environ 3-5 secondes.',
      btn: 'Regarder la vidéo pour en savoir plus'
    },
    cont3: {
      text1: 'Latence ultra-faible',
      text2: 'Haute résolution',
      text3: 'Latence ultra-faible',
      text4: 'Pas de coupures, pas de déconnexions, haute stabilité',
      desc1: 'Le DeepLink Protocol réalise une percée technologique révolutionnaire, offrant une expérience parfaite inégalée.',
      desc2: 'Les membres de l\'équipe technique principale sont dans l\'industrie du jeu en nuage depuis 2011 et proviennent de sociétés telles que Cisco, Intel, Nvidia, Huawei, ShunNet, etc. Après plus de 10 ans de recherche et développement, DeepLink est né.'
    },
    cont16: {
      title1_1: 'DeepLink Protocol fournit des services technologiques à ultra-faible latence pour le jeu en nuage',
      title1_2: 'Fourniture de services technologiques à ultra-faible latence pour le jeu en nuage',
      title2: 'Actuellement, les produits développés sur la base de DeepLink Protocol sont les suivants, et plus de 20 autres applications sont en développement',
      text1: 'Contrôle à distance',
      text2: 'Jeu en nuage & Plateformes de jeu en nuage',
      text3: 'Cybercafés en nuage',
      text4: 'Cloud XR',
      text5: 'XXX Jeu VR en nuage',
      text6: 'YYY Plateforme de jeu en nuage'
    },
    cont4: {
      title1_1: 'DeepLink Protocol',
      title1_2: 'Utilisation des outils de contrôle à distance comme point d’entrée pour le trafic',
      title2: 'Le logiciel DeepLink utilise les outils de contrôle à distance comme point d’entrée pour le trafic, et les plateformes de jeux en nuage ainsi que les cybercafés en nuage comme moyens de monétisation',
      text1_1: '900,000+',
      text1_2: 'Utilisateurs actifs mensuels',
      text2_1: '110+',
      text2_2: 'Nombre de pays offrant des services',
      text3_1: '2,500,000+',
      text3_2: 'Nombre total d’utilisateurs',
      text4_1: '300,000+',
      text4_2: 'Utilisateurs actifs quotidiens',
      desc1: 'Outils de contrôle à distance',
      desc2: 'Jeux en nuage et Cloud XR',
      desc3: 'Cybercafés en nuage',
      btn1: 'YouTube KOL Niveau 1',
      btn2: 'YouTube KOL Niveau 2',
      btn3: 'YouTube KOL Niveau 3',
      btn4: 'YouTube KOL Niveau 4'
    },
    cont17: {
      title1: 'Modèle commercial de DeepLink Protocol',
      text1: 'Modèle de commission',
      text2: 'Modèle de licence',
      desc1: 'Pour chaque transaction de jeu en nuage, DeepLink Protocol prélève une commission de 20-30%, la commission est réglée en DLC et 100% du DLC est immédiatement détruit.',
      desc2: 'Pour les applications telles que les cybercafés en nuage et les hôtels e-sport en nuage, chaque application paie des frais de licence de 6 USD par mois pour chaque terminal informatique.'
    },
    cont5: {
      title1: 'Fournir du trafic réseau inutilisé par les mineurs grâce à la technologie blockchain',
      desc1: 'Connexion du nœud',
      desc2_1: 'Équipement nécessaire',
      desc2_2: 'Transmission du trafic',
      desc3: 'Les mineurs du monde entier peuvent acheter des NFT pour fournir des nœuds de trafic et recevoir des récompenses sous forme de tokens DLC',
      desc3_2: '',
      title2: 'Fournisseurs de nœuds de trafic',
      text2: 'Les mineurs fournissent des nœuds de trafic', 
      title3: 'Côté utilisateur',
      text3: 'Utilisateurs individuels'
    },
    cont6: {
      title1: 'Amélioration significative de la compétitivité de DeepLink sur le marché',
      title2: 'Grâce à la technologie blockchain',
      desc1: 'Les contrats intelligents permettent de garantir des services stables des fournisseurs de GPU par le biais du staking de cryptomonnaies',
      desc2: 'Chacun peut contribuer au réseau et recevoir des récompenses, par exemple en fournissant des serveurs GPU ou des nœuds de trafic',
      desc3: 'L’introduction des mineurs fournissant des nœuds de transmission du trafic permet une large distribution des nœuds sans avoir besoin d’une entreprise centralisée pour déployer les nœuds, ce qui rend le système plus décentralisé et sans coûts de serveur pour l’entreprise',
      desc4: 'Les contrats intelligents et le staking de cryptomonnaies permettent aux investisseurs de sortir du projet et de réaliser un retour sur investissement dans environ 1 an, sans avoir à attendre 5-10 ans',
      desc5: 'Les utilisateurs peuvent participer aux dividendes générés par la croissance du projet, ce qui favorise la diffusion',
      desc6: 'Les utilisateurs peuvent effectuer des paiements sans frontières et libres',
      text1: 'Staking',
      text2: 'Tout le monde peut participer',
      text3: 'Zéro coût',
      text4: 'Temps de retour réduit',
      text5: 'Sans frontières',
      text6: 'Les utilisateurs reçoivent des dividendes'
    },
    cont7: {
      title1: 'Modèle commercial de DeepLink Protocol',
      desc1: "Pour atteindre une bonne expérience de jeu en nuage à faible latence, outre la technologie, les machines GPU doivent être à moins de 50 kilomètres de l'utilisateur, et une couverture mondiale nécessite plus de 50 000 centres de données.",
      title2: "Plateforme de jeux en nuage centralisée traditionnelle",
      title3: 'Plateforme de jeux en nuage basée sur la technologie blockchain de DeepLink',
      text1: "La plateforme achète elle-même les machines, entraînant d'énormes coûts et un retour sur investissement incertain.",
      text2: 'La couverture des machines est limitée, rendant impossible la couverture mondiale.',
      text3: 'La technologie centralisée ne peut pas supporter la connexion de dizaines de milliers de centres de données.',
      text4: "Les mineurs et les propriétaires de cybercafés fournissent des serveurs GPU et reçoivent des récompenses en jetons, ce qui réduit le temps de récupération des investissements pour les mineurs et libère la plateforme de la pression d'achat.",
      text5: 'Le nombre de mineurs et de cybercafés est important, ce qui permet une couverture mondiale.',
      text6: 'La technologie blockchain décentralisée prend en charge plus de 100 000 centres de données et plus de 10 millions de points de serveurs GPU.'
    },
    cont8: {
      title1: "Scénarios d'utilisation du token DeepLink",
      text1: 'Acheter DLC',
      text2: 'Payer en DLC', 
      text3: 'Payer en USDT',
      text4: 'Payer en DLC',
      text5: 'Injecter DLC et USDT dans DEX', 
      text6: 'Vendre DLC',
      text7: 'Utilisateur 1',
      text8: 'Logiciel DeepLink', 
      text9: 'Dont 40% de DLC seront directement détruits sur la chaîne',
      text10: '1. Acheter un NFT',
      text11: '2. Acheter du temps de jeu en cloud', 
      text12: '3. Louer du temps de machine dans un cybercafé',
      text13: '4. Acheter du temps de jeu partagé personnel', 
      text14: 'Dont 40% des USDT seront utilisés pour racheter des DLC sur DEX puis détruits', 
      text15: 'Acheter un NFT',
      text16: 'DEX et CEX',
      text17: "Autres plateformes d'échange",
      text18: 'Mineur',
      text19: 'Récompenses de minage de liquidité',
      text20: 'Récompenses sur 2 ans, 5 milliards de DLC par an'
    },
    cont8_new: {
      text7: 'Utilisateurs individuels',
      text12: '3. Durée de location de machine dans un cybercafé',
      text21: 'Utilisateurs commerciaux',
      text22: 'Protocole DeepLink',
      text23: '1. Acheter une licence',
      text24: '2. Frais de 20-30%',
      text25: '100% des DLC seront directement détruits sur la chaîne'
    },
    cont8_new1: {
      title: 'Les meilleurs YouTubers soutiennent notre projet',
      text1: '',
      text2: 'Utilisateurs professionnels',
      text3: 'Protocole DeepLink',
      text4: '1. Acheter une licence',
    },
    cont9: {
      title1: 'Récompenses de minage de liquidité Token DeepLink',
      text1: 'Utilisateur 1',
      text2: 'Injection de DLC et USDT dans DEX', 
      text3: 'Récompenses de minage de liquidité',
      text4: 'Récompenses sur 2 ans, 5 milliards de DLC par an',
      text5: 'Injection de liquidité DLC et USDT dans Uniswap et Pancake Swap', 
      text6: 'Récompenses distribuées quotidiennement, réception automatique via NFT de liquidité',
      text7: '80% des récompenses sont attribuées aux fournisseurs de liquidité, 20% aux traders',
      text8: 'Récompense quotidienne de 13,698,630 DLC'
    },
    cont9_new: {
      title1: 'Récompenses de minage de liquidité du token DeepLink',
      text1: '5% de DLC ainsi que des valeurs équivalentes en USDT, ETH et BNB.',
      text2: 'Injection dans DEX',
      text3: 'Renforcement de la liquidité de DEX',
      text4: '',
      text5: 'Ces 5% de tokens seront vendus lors de l’IDO (Initial DEX Offering). En fonction du pourcentage réellement vendu, ils seront finalement tous injectés dans le DEX.',
      text6: 'Injection de la liquidité de DLC et USDT dans des DEX tels que Uniswap, SushiSwap, Pancake Swap',
      text7: 'Cette liquidité sera bloquée pendant 5 ans, puis utilisée pour le développement à long terme du projet.',
      text8: ''
    },
    cont10: {
      title: 'Feuille de route et plans',
      desc1: 'Octobre 2020',
      text1: 'Lancement du projet de produit DeepLink',
      desc2: 'Mars 2021',
      text2: "Obtention d'investissements de démarrage",
      desc3: 'Août 2021',
      text3: 'Publication de la première version de développement interne de DeepLink, délai technique atteint 3 ms, prise en charge des claviers et souris de jeu',
      desc4: 'Mai 2022',
      text4: "Publication de la première version de test de la fonction de contrôle à distance de DeepLink, prise en charge de l'affichage 3K 144HZ",
      desc5: 'Août 2022',
      text5: "Publication de la deuxième version de test de la fonction de contrôle à distance de DeepLink, prise en charge de l'affichage virtuel",
      desc6: 'Octobre 2022',
      text6: "Lancement de la version officielle de DeepLink, prise en charge du coréen, du chinois et de l'anglais, et création de la société DeepCloudX à Singapour pour gérer DeepLink",
      desc7: 'Décembre 2022',
      text7: "Obtention d'investissements providentiels",
      desc8: 'Avril 2023',
      text8: "Collaboration avec la plus grande chaîne de cybercafés en Corée, construction d'une salle modèle réelle dans le café, tests de cybercafés en nuage, retours positifs des utilisateurs",
      desc9: 'Juin 2023',
      text9: "Le nombre de téléchargements de DeepLink dépasse 200 000, utilisateurs dans plus de 100 pays, prise en charge de l'affichage 8K 60Hz",
      desc10: 'Janvier 2021',
      text10: 'Détermination de la direction de la recherche et du développement de DeepLink et début de la conception et du développement du produit',
      desc11: 'Avril 2021',
      text11: "Formation complète de l'équipe de base, les membres clés de l'équipe ont commencé à travailler dans l'industrie du jeu en nuage depuis 2011, venant de sociétés telles que Cisco, IntelNvidia, Huawei, Shunwang",
      desc12: 'Mars 2022',
      text12: 'DeepLink commence les tests dans le domaine des cybercafés, le délai technique est réduit à 1 ms',
      desc13: 'Juillet 2022',
      text13: "Publication de la troisième version de test de la fonction de contrôle à distance de DeepLink, prise en charge de l'écran de confidentialité, copie de fichiers à distance",
      desc14: 'Septembre 2022',
      text14: 'Publication de la quatrième version de test de la fonction de contrôle à distance de DeepLink, prise en charge du multi-streaming, prise en charge des couleurs vraies 444',
      desc15: 'Novembre 2022',
      text15: "Installation de 10 mini-PC à Séoul et création d'un environnement de cybercafé virtuel pour les tests de cybercafés en nuage",
      desc16: 'Février 2023',
      text16: "Le nombre de téléchargements de DeepLink dépasse 100 000, utilisateurs dans plus de 50 pays, prise en charge de l'affichage 4K 200HZ",
      desc17: 'Mai 2023',
      text17: 'Le nombre de téléchargements de DeepLink dépasse 150 000, utilisateurs dans plus de 80 pays, prise en charge de la fonction portefeuille DLC, prise en charge du vietnamien, du japonais'
    },
    cont11: {
      title: 'Plan de développement',
      desc1: 'Juillet 2023',
      text1: "Prise en charge de l'ajout de listes d'appareils et de fonctionnalités de récompenses partagées",
      desc2: 'Août 2023',
      text2: 'Lancement du premier cybercafé cloud à Séoul',
      desc3: 'Octobre 2023',
      text3: 'Prise en charge des fonctionnalités de récompenses partagées',
      desc4: 'Décembre 2023',
      text4: 'Prise en charge des fonctionnalités NFT',
      desc5: '1er trimestre 2024',
      text5: "Prise en charge de la fonctionnalité de partage de jeux pour la version WEB et établissement de partenariats, l'objectif est de dépasser 500 000 téléchargements de DeepLink",
      desc6: '2e trimestre 2024',
      text6: "L'objectif est de dépasser 1 000 000 téléchargements de DeepLink et de collaborer avec 10 cybercafés cloud",
      desc7: '3e trimestre 2024',
      text7: "L'objectif est de dépasser 2 000 000 téléchargements de DeepLink, en collaboration avec 3000 ordinateurs partagés et 50 cybercafés cloud",
      desc8: '4e trimestre 2024',
      text8: "Prise en charge des versions iOS, Mac, Vision Pro AR, l'objectif est de dépasser 3 000 000 téléchargements de DeepLink, en collaboration avec 10 000 ordinateurs partagés et 150 cybercafés cloud",
      desc9: '2025',
      text9: "Prise en charge des appareils VR et AR de plusieurs marques, l'objectif est de dépasser 6 000 000 téléchargements de DeepLink, en collaboration avec 20 000 ordinateurs partagés et 300 cybercafés cloud",
      desc10: '2026',
      text10: "L'objectif est de dépasser 15 000 000 téléchargements de DeepLink, en collaboration avec 50 000 ordinateurs partagés et 800 cybercafés cloud"
    },
    cont12: {
      text: '60 milliards émis sur la chaîne DBC, 30 milliards sur la chaîne ETH, 10 milliards sur la chaîne BSC',
      text1: 'Financement',
      text1_1: 'Tour de graine',
      desc1_1: "Déblocage commence 30 jours après l'inscription sur CEX, étalé sur 20 mois, déblocage de 5% chaque mois",
      text1_2: 'Tour des anges',
      desc1_2: "Déblocage commence 30 jours après l'inscription sur CEX, étalé sur 10 mois, déblocage de 10% chaque mois",
      text1_3: 'Tour A',
      desc1_3: "Déblocage commence 30 jours après l'inscription sur CEX, étalé sur 10 mois, déblocage de 10% chaque mois",
      text1_4: 'Prévente et vente publique',
      desc1_4: "40% débloqués immédiatement après l'inscription, le reste sera débloqué à partir du 30 août sur une période de 6 mois, avec un déblocage de 10% par mois. Entre 10% et 30% des fonds levés seront ajoutés à la liquidité DEX (bloqués pendant 3 ans)",
      text2: 'Équipe',
      desc2: "Déblocage commence 60 jours après l'inscription sur CEX, étalé sur 20 mois, déblocage de 5% chaque mois",
      text3: 'Airdrop communautaire',
      desc3: 'Peut être obtenu après avoir rempli certaines tâches et passé la vérification KYC DID',
      text4: 'Vente de NFT',
      desc4: "Les NFT lient une licence logicielle et offrent des TOKEN, déblocage des Token commence après l'inscription sur CEX",
      text5: 'Mining',
      desc5: "16% des parts sont utilisées pour récompenser les mineurs fournissant la puissance GPU, 4% supplémentaires sont utilisés pour le minage de bande passante, cette partie est réduite de moitié tous les 4 ans. Le minage commence 30 jours après l'inscription sur CEX.",
      text6: 'Fondation',
      desc6: "Les tokens commencent à être débloqués 60 jours après l'inscription sur CEX, période de déblocage totale de 2 ans, déblocage trimestriel de 12,5%",
      text7: 'Conseillers et juridique',
      desc7: "Utilisé pour résoudre les problèmes juridiques dans différents pays et récompenser les conseillers, le déblocage commence 30 jours après l'inscription sur CEX, réparti sur 10 mois, déblocage de 10% chaque mois",
      text8: 'Total',
      text9: 'Quantité (milliards) :',
      text10: 'Description :',
      text11: 'En circulation (milliards) :',
      text12: 'Non circulant (milliards) :',
      text13: '100 milliards'
    },
    cont13: {
      title: 'Équipe et Conseillers',
      intro1: 'PDG de DeepLink',
      intro2: 'Cofondateur de DeepLink',
      intro3: 'Directeur des opérations (COO) de DeepLink',
      intro4: 'Directeur commercial (CBO) de DeepLink et leader du marché indien',
      intro5: 'Conseiller',
      intro6: 'Conseiller',
      intro7: 'Conseiller',
      intro8: 'Cofondateur et directeur marketing',
      user1_1: "Entrepreneur en série avec 13 ans d'expérience en intelligence artificielle et 7 ans dans le développement de technologies de réseaux informatiques distribués et blockchain.",
      user1_2: "Diplômé en 2008 de l'Université océanique de Chine en sciences informatiques.",
      user1_3: 'En 2012, il a fondé Ju Shang Intelligent et développé le premier assistant vocal AI chinois, "Smart 360".',
      user1_4: 'En trois ans, le nombre de téléchargements du logiciel a dépassé 17 millions. En 2014, il a lancé le premier haut-parleur AI au monde, "Xiao Zhi Speaker".',
      user1_5: "En mai 2017, il a fondé DeepBrainChain, servant des centaines de clients dans les domaines de l'IA, des jeux en nuage et des cybercafés en nuage.",
      user2_1: "Entrepreneur en série, expert en jeux sur cloud. Anciennement directeur de l'institut de jeux sur cloud de Shunwang, chargé du développement technologique.",
      user2_2: "Diplômé en sciences informatiques de l'Université de Zhejiang en 1999. A travaillé chez LanDe Zhejiang dans le domaine des télécommunications de 2001 à 2004.",
      user2_3: "Il a fondé en 2009 le premier navigateur à double cœur de Chine, 'Sunflower Browser'.",
      user2_4: "De 2010 à 2021, il a rejoint l'entreprise cotée Shunwang et a fondé Shunwang Cloud Computer, occupant le poste de directeur de l'institut Shunwang. Il a fourni des solutions pour les cybercafés en nuage et géré des jeux en nuage, faisant de son entreprise la première en Chine à offrir des solutions de jeux en nuage.",

      user3_1: "Background académique : Diplômé en 2007 du département de langue et littérature chinoises de l'Université de Pékin",
      user3_2: '2023 : Directeur des opérations chez DeepLink',
      user3_3: '2022-2023 : PDG de DeepBrain Chain',
      user3_4: '2019-2021 : Responsable du marché international chez CELLOGIN',
      user3_5: '2018 : PDG de FLOW FACTORY',
      
      user4_1: "Éducation et certifications : - Diplômé en administration des affaires de l'Université de Mysore en 2020.",
      user4_2: '- Certification en marketing numérique.',
      user4_3: '- Certification en optimisation pour les moteurs de recherche (SEO).',
      user4_4: '- Certification blockchain.',
      user4_5: 'Ventes et marketing chez Technic Constructions : - A occupé un poste en ventes et marketing, réalisant 500 000 dollars de ventes en trois mois.',
      user4_6: "Assistant au développement des affaires chez BYJU'S : - Employé de 2020 à 2021.",
      user4_7: '- A joué un rôle clé dans une startup licorne évaluée à 10 milliards de dollars, stimulant la croissance, forgeant des partenariats et simplifiant les opérations des programmes de technologie éducative.',
      user4_8: "Cofondateur de Space Inn : - Cofondé un projet indépendant axé sur le développement des compétences entrepreneuriales, la pensée stratégique et l'acuité commerciale.",
      user4_9: "Investissement/Trading sur le marché boursier : - Accumulé cinq ans d'expérience dans l'investissement et le trading sur le marché boursier, démontrant une expertise dans la navigation et l'exploitation des opportunités d'investissement sur les marchés financiers.",
      user4_10: "Stages dans des entreprises : - Effectué plusieurs stages dans différentes entreprises, acquérant des connaissances précieuses sur la culture d'entreprise, les processus efficaces et les stratégies d'affaires efficaces.",
      user4_11: "Expérience dans l'industrie des cryptomonnaies : - Participation à plusieurs projets de cryptomonnaies et NFT, acquérant une expérience précieuse dans le secteur.",
      user4_12: '- Contribution au projet Real-Fi Cardano Empowa, favorisant son développement et sa croissance. Contribution au projet Cardano Empowa en 2022, stimulant sa croissance.',
      user4_13: '- Collabore actuellement avec DeepLink Cloud, étendant son expertise dans le domaine des cryptomonnaies.',

      user5_1: 'Depuis 2012, vice-président chez Hyunjin ICT, une entreprise de services IoT et de communication, en poste depuis 11 ans.',
      user5_2: 'Gestion des ventes et du marché, expérience dans la réalisation de plusieurs grands projets pour des institutions publiques.',
      user5_3: "A travaillé 14 ans (1998-2012) chez le plus grand entreprise de TI en Corée du Sud, Samsung SDS, spécialisé dans le conseil en stratégie d'information, les projets d'innovation commerciale et le développement logiciel.",
      user5_4: 'A travaillé pendant 3 ans (2017-2019) à la bourse de cryptomonnaies Coinzest, ayant une expérience en tant que numéro un en Corée et parmi les dix premiers mondiaux.',
      user5_5: 'Fondateur de Hyunjin Venus, une entreprise qui gère un centre commercial de bien-être pour de grandes entreprises et institutions publiques en Corée du Sud depuis 5 ans.',
      user5_6: 'Détient les qualifications PMP (Project Management Professional) et CISA (Certified Information Systems Auditor).',
      
      user6_1: 'Formation académique : 2003, École de gestion Guanghua, Université de Pékin',
      user6_2: '2023 : PDG de MetABC',
      user6_3: '2020 : PDG de HillstoneHub',
      user6_4: "2019 : PDG du Centre d'affaires Hillstone",
      user6_5: '2019 : COO de Hillstone Global (Blockchain)',
      user6_6: '2017 : Fondateur de Foundersbridge',
      user6_7: '2017 : Partenaire chez Hillstone Partners (Capital privé)',
      user6_8: '2014 : Cofondateur de BrightA Consulting',
      user6_9: '2012 : Conseiller senior (Expert en affaires chinoises)',

      user7_1: 'Joseph Alexander est le créateur du modèle linguistique à grande échelle ChatDBC.com, conçu sur mesure pour Deep Brain Chain.',
      user7_2: 'Il est spécialisé dans le développement des affaires de DeepLink et DBC en Amérique du Nord.',
      user7_3: 'Vous pouvez le suivre sur Twitter {@}ChatDBC pour toutes les dernières nouvelles concernant DeepLink et DBC.',
      
      user8_1: "Maryna Barysheva est la COO de LKI Consulting, une agence de marketing Web3 primée spécialisée dans les technologies profondes, DeFi, l'infrastructure et les projets de jeux.",
      user8_2: 'Elle a réussi à étendre des projets blockchain dans plus de 23 pays, géré des budgets marketing de 1 million à 10 millions de dollars et dirigé le marketing pour une start-up technologique B2B évaluée à 100 millions de dollars.',
      user8_3: "L'expertise de Maryna réside dans le développement communautaire, la notoriété de la marque et les stratégies GTM mondiales.",
      user8_4: "Elle est une conférencière publique internationale, apparaissant fréquemment lors de conférences sur les cryptomonnaies dans le monde entier et mentor pour des programmes d'incubation sur l'IA, la technologie et la blockchain à l'Université d'Helsinki.",
      
      user9_1: 'Brando est un vétéran du domaine des cryptomonnaies. Il a commencé à étudier les cryptomonnaies en 2016, ce qui a conduit au fameux marché haussier de 2017.',
      user9_2: "Il a fondé Bigger Than Race™️ en 2012 et l'a rebaptisé en son nom actuel en 2017.",
      user9_3: "Ses qualifications incluent une certification Web3/Metaverse par EveryRealm, un fonds de capital-risque et un leader de l'innovation et du développement dans le Metaverse, ainsi que des certifications en blockchain et bitcoin par IBM.",
      user9_4: 'Le génie crypto BRANDO MURPHY',
      user9_5: "Sa vision et son objectif principaux sont d'aider les gens à atteindre la liberté financière grâce à la technologie blockchain et à investir et posséder l'infrastructure de la nouvelle économie des quatrième et cinquième révolutions industrielles. Le VC de la communauté BTR aide les membres fidèles du monde entier à posséder les technologies de l'avenir.",
      user9_6: 'En 2024, il est devenu cofondateur et directeur marketing du DeepLink Protocol, une technologie de jeu en nuage IA qui offre un environnement de compétition équitable pour les joueurs du monde entier. Sa ville natale est Los Angeles, Californie.',
      
      user10_1: "Summer est le fondateur et l'hôte de FireHustle, une entreprise de médias couvrant les projets cryptographiques, les stratégies d'investissement, et plus encore. Summer a un background éducatif en business et une expérience extensive dans la diffusion et la communication.",
      user10_2: 'Son portefeuille médiatique comprend une chaîne YouTube avec plus de 44 000 abonnés, une communauté Discord dédiée avec plus de 3 000 membres, un groupe de membres privé, et plus encore.',
      user10_3: "En termes d'investissement, FireHustle a investi personnellement dans plus de 50 startups web3 et agit en tant que partenaire marketing, de collecte de fonds et de conseil pour de nombreux projets cryptographiques, firmes de capital-risque, plateformes de démarrage, syndicats, groupes KOL, etc.",
      
      user11_1: "Manny est un enthousiaste et un expert en blockchain avec plus de dix ans d'expérience dans le secteur, contribuant à des entreprises et des programmes de premier plan depuis 2013.",
      user11_2: "Son parcours professionnel comprend des positions de départ chez Grayscale, l'équipe opérationnelle de Coinbase, les équipes marketing de DYDX et Ledger, et enfin un poste chez Binance US.",
      user11_3: "'Éducation : Il a complété des cours liés à la blockchain et aux cryptomonnaies dans des institutions de premier plan aux États-Unis (en particulier à l'Université Stanford), y compris 'Blockchain et Cryptomonnaie : Introduction Complète', 'Stratégies Avancées en Blockchain', 'Programme pour les PDG' et 'Programme pour les CMO'. Il est spécialisé en finance et gestion d'entreprise."
    },
    cont14: {
      title: "Nos Institutions d'Investissement",
      organ1: "La Fondation DeepBrain Chain a été fondée en 2017. En janvier 2018, le token DBC a été coté sur la bourse Huobi. Le réseau principal a été lancé en 2021. DeepBrain Chain est un réseau informatique distribué haute performance centré sur le GPU. Sa vision est de construire un réseau informatique haute performance distribué et infiniment évolutif basé sur la technologie blockchain, devenant l'infrastructure informatique essentielle à l'ère de l'AI+Metaverse. Il fournit principalement des services de calcul GPU dans les domaines de l'intelligence artificielle, des jeux en nuage et du rendu de films. Actuellement, il sert des centaines d'entreprises liées à l'intelligence artificielle et aux jeux en nuage, ainsi que plus de 30,000 professionnels de l'IA.",
      organ2: 'Gobi Capital est une société de capital-risque professionnelle basée à Shanghai, Pékin et en Asie du Sud-Est, spécialisée dans les investissements dans les projets technologiques en phase de démarrage en Chine. Les investisseurs stratégiques de Gobi incluent IBM, Sierra Ventures, McGraw-Hill et Steamboat Ventures (le bras de capital-risque de Disney). Gobi a investi dans des startups bien connues comme Tuniu, Camera360 et Zhu Yun.',
      organ3: "Hycons est une plateforme de cloud computing GPU développée sur la technologie DBC et opérée par Hyunjin ICT. Hycons se distingue par sa technologie de réseau haute performance distribué basée sur DBC et sa technologie de streaming HD sans latence basée sur DeepLink. La plateforme offre des services complets, y compris la gestion des membres, l'inscription et le paiement pour divers types de services tels que les entreprises, les écoles, les instituts de recherche et les opérateurs de franchises. Actuellement, Hycons exploite plus de 1 000 cartes GPU et 2 centres en Corée, avec des plans d'extension à 5 centres. Hycons teste également des applications pour le secteur des franchises de cybercafés. Grâce à une base technologique avancée, des tarifs compétitifs pour les services cloud et des capacités de service automatisées, Hycons vise à devenir la principale plateforme de calcul GPU au monde.",
      organ4: "ROCK Capital est un fonds de cryptomonnaies professionnel fondé en 2018 et basé en Corée du Sud, qui réunit des experts en investissement financier, en blockchain et en décentralisation. Il collabore largement avec diverses entreprises nationales et internationales, des projets mondiaux, des gouvernements, des institutions et d'autres entités économiques, ayant mené à bien plus de 100 projets et exploré plusieurs domaines financiers futurs. En renforçant la coopération, la croissance, l'activation et les partenariats, ROCK dispose d'une expérience et d'une expertise approfondies dans des environnements de marché diversifiés. ROCK s'engage à générer des rendements élevés, à élargir les opportunités d'investissement et à maximiser les activités de capital pour créer des expériences concrètes pour les clients.",
      organ5: "Cette société de capital-risque se concentre sur la blockchain décentralisée et les technologies émergentes, visant à promouvoir la quatrième et la cinquième révolutions. Avec une vaste connaissance de divers secteurs, elle est en mesure de saisir les opportunités dans l'ensemble de l'espace web3 et au-delà. Elle co-investit avec des marques renommées telles que Bloktopia, Animoca Brands et Stripe, et a établi des relations solides avec le réseau Singularity, l'écosystème Shiba et divers studios de jeux. Les détenteurs du passe BTR-Army peuvent accéder à leurs services."
    },
    cont15: {
      title: 'Activités Communautaires',
      
    }
  },
  coverage: {
    title: 'Carte de répartition des utilisateurs mondiaux du logiciel DeepLink',
    text: 'Nombre total d\'utilisateurs de DeepLink :'
  },
}